<template>
  <div
    :class="{ 'pl-7': $vuetify.breakpoint.lgAndUp }"
    class="progress-view pa-1"
  >
    <span class="fonte fonteMini grey--text"> tags: </span>
    <sale :dataOrigin="dataOrigin" :dataSale="dataSale" />
  </div>
</template>

<script>
import sale from "./origins/sale.vue";
export default {
  props: {
    dataOrigin: {
      type: Array,
      required: true
    },
    dataSale: {
      type: Object,
      required: true
    }
  },
  components: {
    sale
  }
};
</script>

<style scoped>
.progress-view {
  min-width: 30px;
  height: 100%;
}
</style>
