<template>
  <div
    v-if="$vuetify.breakpoint.lgAndUp"
    style="flex-wrap: nowrap;"
    class="windows-style expande-horizontal centraliza fonte"
  >
    <v-list
      color="transparent"
      :dark="$theme.dark"
      class="fonte pa-0 ma-0"
      style="max-width: 94%;"
      dense
      nav
    >
      <v-list-item class="px-3 mx-0">
        <v-avatar class="mr-2" color="#fff" size="36">
          <v-icon :color="$theme.primary">mdi-network-pos</v-icon>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title class="">
            <span class="fonte">
              {{
                get_gestordepedido.compra.cliente
                  ? get_gestordepedido.compra.cliente.nome
                  : "Cliente não informado"
              }}
            </span>
          </v-list-item-title>
          <span :style="`color: ${$theme.primary}`">
            <span class="fonteMini">
              {{ get_gestordepedido.tipo }} #{{
                get_gestordepedido.id_transacao
              }}
            </span>
            <span class="fonteMini grey--text">
              se encontra na coluna {{ get_gestordepedido.status_do_pedido }},
              criado
              {{
                $moment(get_gestordepedido.created_at).format(
                  "DD/MM/YY [às] HH:mm"
                )
              }}.
            </span>
          </span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-spacer></v-spacer>
    <v-avatar
      @click="fecha_modal_view_gestordepedido"
      style="cursor: pointer;"
      size="27"
      :color="$theme.primary"
      class="mr-2"
      icon
    >
      <v-icon color="#f2f2f2">mdi-close</v-icon>
    </v-avatar>
  </div>
  <v-app-bar
    v-else
    fixed
    style="z-index: 1000"
    class="elevation-2"
  >
    <v-list
      color="transparent"
      :dark="$theme.dark"
      class="fonte pa-0 ma-0"
      style="max-width: 94%;"
      dense
      nav
    >
      <v-list-item class="px-3 pl-0 mx-0">
        <v-avatar @click="fecha_modal_view_gestordepedido" class="mr-2" color="#e5e5e5" size="36">
          <v-icon :color="$theme.secondary">mdi-chevron-left</v-icon>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title class="">
            <span class="fonte">
              {{
                get_gestordepedido.compra.cliente
                  ? get_gestordepedido.compra.cliente.nome
                  : "Cliente não informado"
              }}
            </span>
            <span class="fonteMini blue--text">
              {{ get_gestordepedido.tipo }} #{{
                get_gestordepedido.id_transacao
              }}
            </span>
          </v-list-item-title>
          <span :style="`color: ${$theme.primary}`">
            <v-chip x-small dark>
              {{ get_gestordepedido.status_do_pedido }}
            </v-chip>
            <span class="fonteMini grey--text">
              {{
                $moment(get_gestordepedido.created_at).format(
                  "DD/MM/YY [às] HH:mm"
                )
              }}.
            </span>
          </span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: [
    "openReceiptEmitter",
    "printReceipt",
    "configureDeliveryNote",
    "confirmCancel"
  ],
  computed: {
    ...mapGetters(["get_gestordepedido"])
  },
  methods: {
    ...mapActions(["fecha_modal_view_gestordepedido"])
  }
};
</script>
