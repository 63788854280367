<template>
  <v-menu :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="$theme.primary" dark v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-3 pt-1" style="width: 360px; max-width: 360px;">
      <v-flex class="mb-2 mt-3 fonte" xs12>
        <v-text-field
          dense
          hide-details
          solo-inverted
          flat
          autofocus
          class="fonte"
          :color="$theme.primary"
          v-model="search"
          append-icon="mdi-magnify"
          prepend-inner-icon="mdi-auto-fix"
          placeholder="Busque uma função"
        ></v-text-field>
      </v-flex>
      <div class="plugin-list-container">
        <div
          class="mb-3"
          v-for="(plugin, index) in searchedPlugins"
          :key="index"
        >
          <component
            origin="store"
            :dataOrigin="{ state: 'introduction' }"
            :is="plugin"
          />
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import members from "./plugins/members/index.vue";

export default {
  props: ["context"],
  components: {
    members
  },
  data() {
    return {
      search: "",
      pluginList: [members, members]
    };
  },
  computed: {
    searchedPlugins() {
      if (!this.search) return this.pluginList;
      return this.pluginList.filter(plugin =>
        plugin.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  }
};
</script>

<style>
.plugin-list-container {
  margin-bottom: 10px;
  max-height: 270px;
  overflow: auto;
}

.plugin-list-container::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.plugin-list-container::-webkit-scrollbar-track {
  background: rgb(187, 172, 228); /* color of the tracking area */
  border-radius: 6px;
}

.plugin-list-container::-webkit-scrollbar-thumb {
  background-color: #8e43e4; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
</style>
