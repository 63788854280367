<template>
  <v-list-item
    v-if="dataOrigin.state === 'introduction'"
    @click="() => {}"
    class="item-introduction-list pa-0 ma-0 mb-2"
  >
    <v-avatar rounded size="36" class="mr-3" color="deep-purple">
      <v-icon color="white" size="19">mdi-account-group-outline</v-icon>
    </v-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <span class="fonte font-weight-bold">Membros</span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <span class="fonteMini fonte">Adicione membros </span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="mr-2">
      <v-icon size="19">mdi-connection</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    dataOrigin: {
      type: Object,
      required: true
    }
  }
};
</script>

<style>
.item-introduction-list {
  border-radius: 6px;
  height: 40px;
}
</style>
