<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_abertura_caixa"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal column windows-style">
        <v-list nav color="transparent" class="pa-0 ma-0 pt-0 pb-2">
          <v-list-item class="fonte">
            <v-avatar color="#333" dark class="mr-2">
              <v-icon :color="$theme.primary">mdi-cash-register</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="">
                Abertura de caixa
              </v-list-item-title>
              <span class="fonte ml-0 fonteMini grey--text">
                Confirme seus dados abaixo e caso tenha algum valor em caixa,
                informe-o no campo fundo de caixa.
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar
                @click="fecha_modal_abertura_caixa"
                style="cursor: pointer;"
                :color="$theme.primary"
                dark
                class="mr-2"
              >
                <v-icon color="black">
                  mdi-close
                </v-icon>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>

      <div class="expande-horizontal windows-style-content wrap py-6 pt-0 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-0" xs12>
              <v-list dense class="fonte" color="transparent">
                <v-list-item class="mb-3">
                  <v-avatar color="#333" dark class="mr-2">
                    <v-icon :color="$theme.primary">mdi-account-circle</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ getLoggedUser.nome }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ getLoggedUser.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="mb-3">
                  <v-avatar color="#333" dark class="mr-2">
                    <v-icon :color="$theme.primary">mdi-calendar</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $moment().format("DD/MM/YYYY [às] HH:mm") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Data da abertura
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>

            <v-flex class="px-5" xs12>
              <v-text-field
                v-model="get_caixa.fundo"
                @keyup.enter.prevent="validateOpen"
                outlined
                placeholder="ex: 17,40"
                prefix="R$"
                v-mask="[
                  '#,#',
                  '#,##',
                  '##,##',
                  '###,##',
                  '####,##',
                  '#####,##'
                ]"
                :color="$theme.primary"
                label="Fundo de caixa"
              ></v-text-field>
            </v-flex>
          </v-form>
        </v-flex>
        <div class="px-5 pb-5 expande-horizontal">
          <v-btn
            rounded
            class="elevation-0"
            @click="validateOpen"
            :color="$theme.primary"
          >
            <span class="fonte"> Abrir o caixa </span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_modal_abertura_caixa", "get_caixa", "getLoggedUser"])
  },
  methods: {
    ...mapActions(["criar_caixa", "fecha_modal_abertura_caixa"]),
    validateOpen() {
      if (this.$refs.form.validate()) {
        this.criar_caixa(this.get_caixa);
      }
    }
  }
};
</script>
