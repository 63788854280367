<template>
  <div>
    <icon v-if="dataOrigin.state === 'icon'" :dataOrigin="dataOrigin" />
    <introduction
      v-if="dataOrigin.state === 'introduction'"
      :dataOrigin="dataOrigin"
    />
    <!-- <configure :dataOrigin="dataOrigin" /> -->
    <inactive v-if="dataOrigin.state === 'inective'" :dataOrigin="dataOrigin" />
    <loading v-if="dataOrigin.state === 'loading'" :dataOrigin="dataOrigin" />
    <progress-view
      :origin="origin"
      :dataOrigin="dataOrigin"
      :withoutTitle="withoutTitle"
    />
    <warning v-if="dataOrigin.state === 'warning'" :dataOrigin="dataOrigin" />
    <done v-if="dataOrigin.state === 'done'" :dataOrigin="dataOrigin" />
  </div>
</template>

<script>
import icon from "./icon.vue";
import introduction from "./introduction.vue";
import inactive from "./inactive.vue";
import loading from "./loading.vue";
import progressView from "./progressView/index.vue";
import warning from "./warning.vue";
import done from "./done.vue";

export default {
  name: "Membros",
  props: {
    origin: {
      type: String,
      required: true
    },
    dataOrigin: {
      type: Object,
      required: true
    },
    withoutTitle: {
      type: Boolean,
      default: false
    }
  },
  components: {
    icon,
    introduction,
    inactive,
    loading,
    progressView,
    warning,
    done
  }
};
</script>
