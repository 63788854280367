<template>
  <div
    v-show="false"
    class="print-unbreak"
    style="page-break-after: always !important; width: 100% !important; display: flex !important;  flex-wrap: nowrap !important;"
  >
    <div
      style="width: 50%; border-right: 1px dashed #333; padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
    >
      <div
        style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
      >
        <img :style="`min-height: 55px !important; max-height: 55px !important;`" :src="getLoggedUser.tenant[0].profileImage" alt="" />
        <div style="display: flex; align-items: center; flex-direction: column; padding-right: 7px; padding-left: 7px; ">
          <span class="font-head-title">
            {{ getLoggedUser.tenant[0].nome }}
          </span>
          <span class="font-head-subtitle">
            CNPJ: {{ getLoggedUser.tenant[0].cnpj }}
          </span>
          <span
            v-if="getLoggedUser.tenant[0].address"
            class="font-head-subtitle"
          >
            {{ getLoggedUser.tenant[0].address.formatted_address }}
          </span>
          <span
            class="font-head-subtitle"
            v-if="getLoggedUser.tenant[0].phone"
          >
            {{ getLoggedUser.tenant[0].phone }}
          </span>
        </div>
      </div>
      <div
        class="mt-3"
        style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
      >
        <span class="font-title">NOTA DE ENTREGA</span>
      </div>
      <div
        style="padding: 12px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
      >
        <div style="display: flex; width: 100%;" v-if="lastSell.compra.cliente">
          <span class="font-head-title" style="margin-right: 18px;">
            Cliente:
          </span>
          <div style="padding-left: 2px; display: flex; min-width: 90%; border-bottom: 1px solid #f2f1f1;">
            <span class="font-head-subtitle">
              {{ lastSell.compra.cliente.nome }}       
            </span>
          </div>
        </div>
        <div style="display: flex; width: 100%; margin-top: 4px;" v-if="lastSell.compra.cliente">
          <span class="font-head-title" style="margin-right: 6px;">
            Telefone:
          </span>
          <div style="padding-left: 2px; display: flex; min-width: 90%; border-bottom: 1px solid #f2f1f1;">
            <span class="font-head-subtitle">
              {{ lastSell.compra.cliente.telefone }}
            </span>
          </div>
        </div>
        <div style="display: flex; width: 100%; margin-top: 4px;">
          <span class="font-head-title" style="margin-right: 10px;">
            Endereço:
          </span>
          <div style="padding-left: 2px; display: flex; min-width: 75%; border-bottom: 1px solid #f2f1f1;">
            <span v-if="lastSell.compra.address" class="font-head-subtitle">
              {{ lastSell.compra.address.street }}, BAIRRO
              {{ lastSell.compra.address.district }} Nº
              {{ lastSell.compra.address.number }},
              {{ lastSell.compra.address.city }} - {{ lastSell.compra.address.state }}
            </span>
          </div>
        </div>
      </div>
      <table style="width: 100%;background: #f0f0f0;">
        <tr
          class="tr-table"
          style="width: 100%; background: #f7f7f7; border: 1px solid #f0f0f0;"
        >
          <td
            style="padding-right: 2px; text-align: center; padding-left: 2px; color: #878686; border-right: 1px solid #D2D4CF;"
          >
            <span class="font-head-title">
              QTD
            </span>
          </td>
          <td
            style="padding-right: 10px; padding-left: 10px; color: #878686; border-right: 1px solid #D2D4CF;"
          >
            <span class="font-head-title">
              D E S C R I M I N A Ç Ã O
            </span>
          </td>
          <td
            style="padding-right: 10px; padding-left: 10px; color: #878686; border-right: 1px solid #D2D4CF;"
          >
            <span class="font-head-title">
              MEDIDA
            </span>
          </td>
        </tr>
        <template
          v-for="(item, i2) in lastSell.compra.produtos"
        >
          <tr
            style="background: #cccaca; border-bottom: 3px dashed #cccaca;"
            :key="`desc-perso-${i2}`"
          >
            <td
              class="fonte fonte-micro"
              style="color: #cccaca; padding-right: 10px; padding-left: 10px;"
            >
              <span class="font-head-subtitle">
                ---
              </span>
            </td>
            <td
              class="fonte fonte-micro"
              style="color: #cccaca;padding-right: 10px; padding-left: 10px; width: 70%;"
            >
              <span class="font-head-title">
                {{
                  item.descricao_personalizada
                    ? item.descricao_personalizada
                    : item.nome
                }}
              </span>
            </td>
            <td style="color: #cccaca"> <span class="font-head-subtitle"> --- </span> </td>
          </tr>
          <tr
            v-for="(ficha, i) in item.ficha_tecnica"
            class="tr-table"
            style="background: #f0f0f0; width: 100%;"
            :key="`item-ficha-prod-print-1-${i}-${i2}`"
            v-show="ficha.quantity && ficha.quantity >= 1 && (ficha.type && ficha.type === 'Peça Principal')"
          >
            <td
              class="fonte fonte-micro"
              style="color: #f7f7f7; text-align: center;"
            >
              <span class="font-head-title">
                {{ ficha.quantity }}
              </span>
            </td>
            <td
              class="fonte fonte-micro"
              style="padding-right: 10px; padding-left: 10px;"
            >
              <span class="font-head-subtitle">
                {{ ficha.descricao ? ficha.descricao : "" }} -
                {{ item.nome }}
              </span>

            </td>
            <td
              class="fonte fonte-bold"
              style="padding-right: 15px; padding-left: 10px; text-wrap: nowrap;"
              v-if="ficha.comprimento || ficha.largura"
            >
              <span class="font-head-subtitle">
                {{ parseFloat(ficha.comprimento).toFixed(2) }} x {{ parseFloat(ficha.largura).toFixed(2) }}
              </span>
            </td>
            <td
              class="fonte fonte-bold"
              style="color: #494949;padding-right: 10px; padding-left: 10px;"
              v-else
            >
              <span class="font-head-subtitle">
                ##
              </span>
            </td>
          </tr>
        </template>
      </table>
      <div style="display: flex; width: 100%; flex-direction: column; margin-top: 10px;">
        <div style="display: flex; width: 100%; margin-top: 4px;">
          <span class="font-head-title" style="margin-right: 6px;">
            LOCAL: 
          </span>
          <div style="padding-left: 2px; display: flex; min-width: 90%; border-bottom: 1px solid #8f8e8e;">
            <span class="font-head-subtitle">
            </span>
          </div>
        </div>
        <div style="display: flex; width: 100%; margin-top: 12px;">
          <span class="font-head-title" style="margin-right: 6px;">
            DATA: _____ / _____ / ____________
          </span>
          <!-- <div style="padding-left: 2px; display: flex; min-width: 90%; border-bottom: 1px solid #8f8e8e;">
            <span class="font-head-subtitle">
            </span>
          </div> -->
        </div>
      </div>
      <div style="display: flex; width: 100%; flex-wrap: nowrap; margin-top: 40px;">
        <div style="display: flex; width: 50%; flex-direction: column; align-items: center;">
          <div>
            ________________________________
          </div>
          <span class="text-head-title">
            RECEBIDO POR
          </span>
        </div>
        <div style="display: flex; width: 50%; flex-direction: column; align-items: center;">
          <div>
            ________________________________
          </div>
          <span class="text-head-title">
            {{ getLoggedUser.nome }}
          </span>
        </div>
      </div>
      <span class="font-head-title" style="width: 100%; text-align: center; margin-top: 12px;">
        OBS: FAVOR CONFERIR PEÇAS NO ATO DA ENTREGA. NÃO NOS
        RESPONSABILIZAMOS POR DANOS CAUSADOS APÓS A
        ENTREGA.
      </span>
    </div>
    <div
      style="width: 50%; border-left: 1px dashed #333; padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
    >
      <div
        style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
      >
        <img :style="`min-height: 55px !important; max-height: 55px !important;`" :src="getLoggedUser.tenant[0].profileImage" alt="" />
        <div style="display: flex; align-items: center; flex-direction: column; padding-right: 7px; padding-left: 7px; ">
          <span class="font-head-title">
            {{ getLoggedUser.tenant[0].nome }}
          </span>
          <span class="font-head-subtitle">
            CNPJ: {{ getLoggedUser.tenant[0].cnpj }}
          </span>
          <span
            v-if="getLoggedUser.tenant[0].address"
            class="font-head-subtitle"
          >
            {{ getLoggedUser.tenant[0].address.formatted_address }}
          </span>
          <span
            class="font-head-subtitle"
            v-if="getLoggedUser.tenant[0].phone"
          >
            {{ getLoggedUser.tenant[0].phone }}
          </span>
        </div>
      </div>
      <div
        class="mt-3"
        style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
      >
        <span class="font-title">NOTA DE ENTREGA</span>
      </div>
      <div
        style="padding: 12px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
      >
        <div style="display: flex; width: 100%;" v-if="lastSell.compra.cliente">
          <span class="font-head-title" style="margin-right: 18px;">
            Cliente:
          </span>
          <div style="padding-left: 2px; display: flex; min-width: 90%; border-bottom: 1px solid #f2f1f1;">
            <span class="font-head-subtitle">
              {{ lastSell.compra.cliente.nome }}       
            </span>
          </div>
        </div>
        <div style="display: flex; width: 100%; margin-top: 4px;" v-if="lastSell.compra.cliente">
          <span class="font-head-title" style="margin-right: 6px;">
            Telefone:
          </span>
          <div style="padding-left: 2px; display: flex; min-width: 90%; border-bottom: 1px solid #f2f1f1;">
            <span class="font-head-subtitle">
              {{ lastSell.compra.cliente.telefone }}
            </span>
          </div>
        </div>
        <div style="display: flex; width: 100%; margin-top: 4px;">
          <span class="font-head-title" style="margin-right: 10px;">
            Endereço:
          </span>
          <div style="padding-left: 2px; display: flex; min-width: 75%; border-bottom: 1px solid #f2f1f1;">
            <span v-if="lastSell.compra.address" class="font-head-subtitle">
              {{ lastSell.compra.address.street }}, BAIRRO
              {{ lastSell.compra.address.district }} Nº
              {{ lastSell.compra.address.number }}, 
              {{ lastSell.compra.address.city }} - {{ lastSell.compra.address.state }}
            </span>
          </div>
        </div>
      </div>
      <table style="witdh: 100% !important; background: #f0f0f0;">
        <template>
            <tr
              class="tr-table"
              style="width: 100%; background: #f7f7f7; border: 1px solid #f0f0f0;"
            >
              <td
                style="padding-right: 2px; text-align: center; padding-left: 2px; color: #878686; border-right: 1px solid #D2D4CF;"
              >
                <span class="font-head-title">
                  QTD
                </span>
              </td>
              <td
                style="padding-right: 10px; padding-left: 10px; color: #878686; border-right: 1px solid #D2D4CF;"
              >
                <span class="font-head-title">
                  D E S C R I M I N A Ç Ã O
                </span>
              </td>
              <td
                style="padding-right: 10px; padding-left: 10px; color: #878686; border-right: 1px solid #D2D4CF;"
              >
                <span class="font-head-title">
                  MEDIDA
                </span>
              </td>
            </tr>
            <template
              v-for="(item, i2) in lastSell.compra.produtos"
            >
              <tr
                style="background: #cccaca; border-bottom: 3px dashed #cccaca;"
                :key="`desc-perso-${i2}`"
              >
                <td
                  class="fonte fonte-micro"
                  style="color: #cccaca; padding-right: 10px; padding-left: 10px;"
                >
                  <span class="font-head-subtitle">
                    ---
                  </span>
                </td>
                <td
                  class="fonte fonte-micro"
                  style="color: #cccaca;padding-right: 10px; padding-left: 10px; width: 70%;"
                >
                  <span class="font-head-title">
                    {{
                      item.descricao_personalizada
                        ? item.descricao_personalizada
                        : item.nome
                    }}
                  </span>
                </td>
                <td style="color: #cccaca"> <span class="font-head-subtitle"> --- </span> </td>
              </tr>
              <tr
                v-for="(ficha, i) in item.ficha_tecnica"
                class="tr-table"
                style="background: #f0f0f0; width: 100%;"
                :key="`item-ficha-prod-print-2-${i}-${i2}`"
                v-show="ficha.quantity && ficha.quantity >= 1 && (ficha.type && ficha.type === 'Peça Principal')"
              >
                <td
                  class="fonte fonte-micro"
                  style="color: #f7f7f7; text-align: center;"
                >
                  <span class="font-head-title">
                    {{ ficha.quantity }}
                  </span>
                </td>
                <td
                  class="fonte fonte-micro"
                  style="padding-right: 10px; padding-left: 10px;"
                >
                  <span class="font-head-subtitle">
                    {{ ficha.descricao ? ficha.descricao : "" }} -
                    {{ item.nome }}
                  </span>

                </td>
                <td
                  class="fonte fonte-bold"
                  style="padding-right: 15px; padding-left: 10px; text-wrap: nowrap;"
                  v-if="ficha.comprimento || ficha.largura"
                >
                  <span class="font-head-subtitle">
                    {{ parseFloat(ficha.comprimento).toFixed(2) }} x {{ parseFloat(ficha.largura).toFixed(2) }}
                  </span>
                </td>
                <td
                  class="fonte fonte-bold"
                  style="color: #494949;padding-right: 10px; padding-left: 10px;"
                  v-else
                >
                  <span class="font-head-subtitle">
                    ##
                  </span>
                </td>
              </tr>
            </template>
        </template>
      </table>
      <div style="display: flex; width: 100%; flex-direction: column; margin-top: 10px;">
        <div style="display: flex; width: 100%; margin-top: 4px;">
          <span class="font-head-title" style="margin-right: 6px;">
            LOCAL: 
          </span>
          <div style="padding-left: 2px; display: flex; min-width: 90%; border-bottom: 1px solid #8f8e8e;">
            <span class="font-head-subtitle">
            </span>
          </div>
        </div>
        <div style="display: flex; width: 100%; margin-top: 12px;">
          <span class="font-head-title" style="margin-right: 6px;">
            DATA: _____ / _____ / ____________
          </span>
          <!-- <div style="padding-left: 2px; display: flex; min-width: 90%; border-bottom: 1px solid #8f8e8e;">
            <span class="font-head-subtitle">
            </span>
          </div> -->
        </div>
      </div>
      <div style="display: flex; width: 100%; flex-wrap: nowrap; margin-top: 40px;">
        <div style="display: flex; width: 50%; flex-direction: column; align-items: center;">
          <div>
            ________________________________
          </div>
          <span class="text-head-title">
            RECEBIDO POR
          </span>
        </div>
        <div style="display: flex; width: 50%; flex-direction: column; align-items: center;">
          <div>
            ________________________________
          </div>
          <span class="text-head-title">
            {{ getLoggedUser.nome }}
          </span>
        </div>
      </div>
      <span class="font-head-title" style="width: 100%; text-align: center; margin-top: 12px;">
        OBS: FAVOR CONFERIR PEÇAS NO ATO DA ENTREGA. NÃO NOS
        RESPONSABILIZAMOS POR DANOS CAUSADOS APÓS A
        ENTREGA.
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['lastSell', 'getLoggedUser'],
    methods: {
      abre_modal_view_caixa(item) {
        // this.$emit('abre_modal_view_caixa', item)
      }
    }
  }
</script>