<template>
  <div v-if="dataOrigin.state === 'warning'" class="warning"></div>
</template>

<script>
export default {
  props: {
    dataOrigin: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.warning {
  width: 100%;
  height: 100%;
  background: #333;
}
</style>
