<template>
  <div
    :class="{
      'pl-7':
        $vuetify.breakpoint.lgAndUp && !withoutTitle && origin !== 'cardsale'
    }"
    v-if="dataOrigin.state === 'progress-view'"
    class="progress-view pa-1"
  >
    <span class="fonte fonteMini grey--text" v-if="origin !== 'cardsale'">
      Envolvidos:
    </span>
    <sale v-if="origin === 'sale'" :dataOrigin="dataOrigin"></sale>
    <cardsale v-if="origin === 'cardsale'" :dataOrigin="dataOrigin"></cardsale>
  </div>
</template>

<script>
import sale from "./origins/sale.vue";
import cardsale from "./origins/sale.vue";
export default {
  props: {
    origin: {
      type: String,
      required: true
    },
    dataOrigin: {
      type: Object,
      required: true
    },
    withoutTitle: {
      type: Boolean,
      default: false
    }
  },
  components: {
    sale,
    cardsale
  }
};
</script>

<style scoped>
.progress-view {
  width: 100%;
  min-width: 30px;
  height: 100%;
}
</style>
