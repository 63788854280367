<template>
  <div class="expande-horizontal pa-1">
    <baseMember
      v-for="member in members"
      :member="member"
      :key="member._id"
      :size="22"
      class=""
    />
  </div>
</template>

<script>
import baseMember from "../base.vue";
export default {
  props: {
    dataOrigin: {
      type: Object,
      required: true
    }
  },
  computed: {
    members() {
      let members = [];
      if (this.dataOrigin.caixa.funcionario) {
        members.push({ ...this.dataOrigin.caixa.funcionario, paper: "caixa" });
      }
      if (this.dataOrigin.compra.cliente) {
        members.push({ ...this.dataOrigin.compra.cliente, paper: "cliente" });
      }
      return members;
    }
  },
  components: {
    baseMember
  }
};
</script>

<style scoped>
.sale {
  width: 100%;
  height: 100%;
  background: #333;
}
</style>
