<template>
  <v-dialog fullscreen transition="slide-x-transition" v-model="modal">
    <div class="expande-horizontal expande-vertical-vh vitrify centraliza">
      <v-flex xs12 md10>
        <div class="animate__animated expande-horizontal column">
          <div class="expande-horizontal centraliza wrap py-6 pb-0">
            <v-flex xs12 md8>
              <div class="expande-horizontal wrap">
                <v-card
                  color=""
                  class="expande-horizontal"
                  style="min-height: 77vh;"
                >
                  <div class="expande-horizontal">
                    <v-flex
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="pa-6"
                      xs12
                      md6
                    >
                      <div
                        style="min-height: 60vh;"
                        class="expande-horizontal pa-6 centraliza"
                      >
                        <LottieAnimation
                          v-if="loading === 'loading_rocket'"
                          class="pa-6"
                          ref="anim"
                          :loop="true"
                          :animationData="
                            require('@/apps/shared/assets/loading_rocket.json')
                          "
                        />
                        <LottieAnimation
                          v-if="loading === 'success'"
                          class="pa-6"
                          ref="anim2"
                          :animationData="
                            require('@/apps/shared/assets/activate_account_success.json')
                          "
                        />
                        <LottieAnimation
                          v-if="loading === 'man_typing'"
                          :loop="true"
                          class="pa-6 animate__fadeInUp"
                          ref="anim3"
                          :animationData="
                            require('@/apps/shared/assets/expense.json')
                          "
                        />
                      </div>
                    </v-flex>
                    <v-flex class="pa-6" xs12 md6>
                      <v-window v-model="step">
                        <v-window-item :value="1">
                          <div
                            style="min-height: 57vh;"
                            transition="fade"
                            class="pt-6 mt-3 expande-horizontal centraliza fonte animate__fadeInLeft animate__delay-2s wrap"
                          >
                            <v-flex class="pa-5 pb-2" xs12>
                              <div
                                class="expande-horizontal column animate__fadeIn"
                              >
                                <h1 class="fonte" style="color: #1173ff;">
                                  {{
                                    $helper.formataSaldo(
                                      parseFloat(get_compra.preco_total) -
                                        (get_compra.total_recebido || 0)
                                    )
                                  }}
                                </h1>
                                <span class="fonte grey--text"
                                  >Escolha a forma de pagamento...</span
                                >
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <v-flex xs12 class="">
                                <v-list
                                  v-if="loading === 'man_typing'"
                                  nav
                                  two-line
                                  class="animate__fadeInRight animate__animated pa-0 ma-0 pb-6"
                                >
                                  <v-list-item
                                    :class="
                                      indexByArrow === 0
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.money.active"
                                    @click="selectPaymentMethod('dinheiro')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-cash</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        1 Dinheiro
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 1
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.pix.active"
                                    @click="selectPaymentMethod('dinheiro')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-cash</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        2 Pix
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 2
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.card.active"
                                    @click="selectPaymentMethod('credito')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-credit-card</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        3 Crédito
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 3
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.debit.active"
                                    @click="selectPaymentMethod('debito')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-credit-card</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        4 Débito
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-btn
                                  @click="close"
                                  dark
                                  text
                                  class="fonte animate__delay-2s animate__fadeIn grey--text text-capitalize mt-6"
                                  >Cancelar
                                  <v-icon size="16" class="mt-1" color="grey"
                                    >mdi-close</v-icon
                                  >
                                </v-btn>
                              </v-flex>
                            </v-flex>
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            transition="fade"
                            v-if="loading === 'man_typing'"
                            style="min-height: 57vh;"
                            class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #1173ff;">
                                  {{ methodsConverted[this.indexByArrow] }}
                                </h2>
                                <span class="grey--text fonte">
                                  Informe o valor abaixo
                                </span>
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <div
                                class="expande-horizontal wrap"
                                style="max-height: 300px; overflow: auto; overflow-x: hidden;"
                              >
                                <div class="pb-6">
                                  <h1 style="color: #1173ff;" class="fonte">
                                    {{
                                      $helper.formataSaldo(
                                        valueToPayInSelectedMethod || 0
                                      )
                                    }}
                                  </h1>
                                </div>
                                <v-flex xs12>
                                  <v-text-field
                                    prefix="R$"
                                    label="Informe o valor"
                                    hint="Informe todo ou parte do valor"
                                    clearable
                                    @keyup.enter.prevent="reportValueReceived"
                                    autofocus
                                    v-mask="[
                                      '#.##',
                                      '##.##',
                                      '###.##',
                                      '####.##',
                                      '#####.##'
                                    ]"
                                    v-model="valueToPayInSelectedMethod"
                                  ></v-text-field>
                                </v-flex>
                              </div>
                              <div class="expande-horizontal">
                                <v-btn
                                  @click="step = 1"
                                  dark
                                  text
                                  class="fonte animate__delay-2s animate__fadeIn grey--text text-capitalize mt-3"
                                >
                                  <v-icon size="16" class="mt-1" color="grey"
                                    >mdi-chevron-left</v-icon
                                  >
                                  voltar
                                </v-btn>
                                <v-spacer></v-spacer>
                              </div>
                            </v-flex>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-flex>
                  </div>
                </v-card>
              </div>
            </v-flex>
          </div>
        </div>
      </v-flex>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { EventBus } from "@/main.js";
// import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  // components: { ModalSendArchive },
  props: {
    sendToPreparing: {
      type: Function,
      default: () => {}
    },
    payment_sys_config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      methods: ["dinheiro", "pix", "credito", "debito"],
      methodsConverted: ["Dinheiro", "Pix", "Crédito", "Débito"],
      modal: false,
      valueToPayInSelectedMethod: "",
      loading: "",
      step: 1,
      indexByArrow: 0,
      remainingToPay: false,
      type: "" //payment_method_selected
    };
  },
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(["get_invoice", "getLoggedUser", "get_compra"]),
    placeholderDescription() {
      let description = "";
      if (!this.get_invoice.expense_type) {
        description = "Ex: Pagamento do Luiz...";
      }
      if (this.get_invoice.expense_type) {
        description = this.get_invoice.expense_type.description;
      }
      return description;
    }
  },
  watch: {
    "get_invoice.saved": function(val) {
      if (val) {
        this.setLoading("success");
        this.step = 4;
      }
    }
  },
  methods: {
    selectPaymentMethod(method) {
      this.type = method;
      // valor que ainda falta para pagar
      const remainingToPay =
        parseFloat(this.get_compra.preco_total) -
        (this.get_compra.total_recebido || 0);
      this.valueToPayInSelectedMethod = remainingToPay.toFixed(2);

      this.step = 2;
    },
    reportValueReceived() {
      if (this.valueToPayInSelectedMethod > 0) {
        this.get_compra[this.type] = parseFloat(
          this.valueToPayInSelectedMethod
        ).toFixed(2);
        this.checkIfPaymentIsComplete();
      }
    },
    checkIfPaymentIsComplete() {
      let total_recebido = 0;
      if (this.get_compra.dinheiro && this.get_compra.dinheiro > 0) {
        total_recebido += parseFloat(this.get_compra.dinheiro);
      }
      if (this.get_compra.pix && this.get_compra.pix > 0) {
        total_recebido += parseFloat(this.get_compra.pix);
      }
      if (this.get_compra.credito && this.get_compra.credito > 0) {
        total_recebido += parseFloat(this.get_compra.credito);
      }
      if (this.get_compra.debito && this.get_compra.debito > 0) {
        total_recebido += parseFloat(this.get_compra.debito);
      }

      console.log("total recebido", total_recebido);
      console.log("preco total", this.get_compra.preco_total);
      let total_recebido_fixed = parseFloat(total_recebido).toFixed(2);
      let preco_total_fixed = parseFloat(this.get_compra.preco_total).toFixed(
        2
      );

      if (total_recebido_fixed < preco_total_fixed) {
        setTimeout(() => {
          this.step = 1;
        }, 300);
      } else if (total_recebido_fixed >= preco_total_fixed) {
        this.paymentOk();
      }
      this.get_compra.total_recebido = total_recebido_fixed;
      this.valueToPayInSelectedMethod = "";
      this.type = "";
    },
    paymentOk() {
      this.get_compra.status_do_pagamento = "pago";
      this.sendToPreparing();
      this.close();
    },
    setLoading(state) {
      this.loading = "";
      setTimeout(() => {
        this.loading = state;
      }, 300);
    },
    open() {
      this.modal = false;
      this.modal = true;
      this.setLoading("");
      setTimeout(() => {
        this.setLoading("man_typing");
        this.step = 1;
      }, 100);
    },
    close() {
      this.modal = false;
    },
    stepBack() {
      if (this.step === 1) {
        this.close();
      } else {
        this.step--;
      }
    }
  },
  mounted() {
    EventBus.$on("ArrowUp", () => {
      if (this.indexByArrow > 0) {
        this.indexByArrow--;
      }
    });
    EventBus.$on("ArrowDown", () => {
      if (this.indexByArrow < 3) {
        this.indexByArrow++;
      }
    });
    EventBus.$on("ArrowLeft", () => {
      this.stepBack();
    });
    EventBus.$on("ArrowRight", () => {
      if (this.step === 1) {
        this.selectPaymentMethod(this.methods[this.indexByArrow]);
      }
    });
    EventBus.$on("Enter", () => {
      if (this.step === 1) {
        this.selectPaymentMethod(this.methods[this.indexByArrow]);
      }
    });
  }
};
</script>

<style>
.paymentMethodPreSelected {
  background: #f0f0f0;
  border-radius: 6px;
}
</style>
