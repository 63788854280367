<template>
  <v-menu light open-on-hover bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        hover
        style="cursor: pointer;"
        :color="randomColor"
        v-bind="attrs"
        class="mr-1"
        v-on="on"
        :size="size"
      >
        <v-img v-if="photo" :src="photo"></v-img>
        <span v-else class="white--text fonteMini fonte" :class="classFonte">{{
          firsTwoLettersName
        }}</span>
      </v-avatar>
    </template>

    <v-card light>
      <v-list color="transparent" dense>
        <v-list-item class="fonte">
          <v-avatar class="mr-2">
            <v-img v-if="photo" :src="photo"></v-img>
            <v-icon v-else>mdi-account-circle</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{
              member.name || member.nome
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ member.email }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="fonteMini font-weight-bold">
              Como {{ member.paper }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      default: () => {}
    },
    size: {
      type: Number,
      default: 31
    },
    classFonte: {
      type: String,
      default: "font-weight-bold"
    }
  },
  computed: {
    photo() {
      if (this.member.photo && Array.isArray(this.member.photo))
        return this.member.photo[0];
      if (this.member.photo && !Array.isArray(this.member.photo))
        return this.member.photo;
      if (!this.member.photo) return false;
      return false;
    },
    randomColor() {
      let colors = [
        "pink",
        "purple",
        "deep-purple",
        "indigo",
        "cyan",
        "teal",
        "brown",
        "blue-grey"
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    firsTwoLettersName() {
      if (this.member.name) {
        let name = this.member.name.split(" ");
        if (name.length === 1) {
          return name[0][0];
        } else {
          return name[0][0] + name[1][0];
        }
      } else if (this.member.nome) {
        let name = this.member.nome.split(" ");
        if (name.length === 1) {
          return name[0][0];
        } else {
          return name[0][0] + name[1][0];
        }
      }
      return "";
    }
  }
};
</script>
