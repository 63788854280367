<template>
  <div ref="canvasContainer" class="canvas-container"></div>
</template>

<script>
import * as THREE from 'three';

export default {
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      cube: null,
      raycaster: new THREE.Raycaster(),
      mouse: new THREE.Vector2(),
      isDragging: false,
      dragOffset: new THREE.Vector3(),
      selectedObject: null
    };
  },
  mounted() {
    setTimeout(() => {
      this.initScene();
      this.animate();
      window.addEventListener('resize', this.onWindowResize);
      this.$refs.canvasContainer.addEventListener('mousedown', this.onMouseDown);
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    }, 300);
  },
  methods: {
    initScene() {
      const container = this.$refs.canvasContainer;
      const width = container.clientWidth;
      const height = container.clientHeight;

      // Verifica se o container foi corretamente referenciado
      if (!container) {
        console.error('Container not found');
        return;
      }

      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(80, width / height, 0.1, 1000);
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(width, height);
      container.appendChild(this.renderer.domElement);

      // Adicionar um cubo
      const geometry = new THREE.BoxGeometry();
      const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      this.cube = new THREE.Mesh(geometry, material);
      this.scene.add(this.cube);

      this.camera.position.z = 5;
    },
    animate() {
      const self = this;
      requestAnimationFrame(self.animate.bind(self));
      this.cube.rotation.x += 0.01;
      this.cube.rotation.y += 0.01;
      this.renderer.render(this.scene, this.camera);
    },
    onWindowResize() {
      const container = this.$refs.canvasContainer;
      const width = container.clientWidth;
      const height = container.clientHeight;

      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
    },
    onMouseDown(event) {
      this.isDragging = true;
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObjects(this.scene.children);
      if (intersects.length > 0) {
        this.selectedObject = intersects[0].object;
        const intersectPoint = intersects[0].point;
        this.dragOffset.copy(intersectPoint).sub(this.selectedObject.position);
      }
    },
    onMouseMove(event) {
      if (this.isDragging && this.selectedObject) {
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        this.raycaster.setFromCamera(this.mouse, this.camera);
        const intersects = this.raycaster.intersectObjects([this.selectedObject]);
        if (intersects.length > 0) {
          const intersectPoint = intersects[0].point;
          this.selectedObject.position.copy(intersectPoint.sub(this.dragOffset));
        }
      }
    },
    onMouseUp() {
      this.isDragging = false;
      this.selectedObject = null;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
    this.$refs.canvasContainer.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  }
};
</script>

<style scoped>
.canvas-container {
  width: 100%;
  height: 100vh;
}
</style>
