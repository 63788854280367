<template>
  <v-dialog width="300" v-model="opened" transition="slide-x-transition">
    <div style="background: #f2f2f2;" class="expande-horizontal wrap">
      <v-app-bar>
        <v-flex xs12>
          <v-list-item>
            <v-btn icon @click="close()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-list-item-content>
              <v-list-item-title class="fonte"> Desconto </v-list-item-title>
              <v-list-item-subtitle class="fonte">
                O valor será adicionado à compra
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-flex>
      </v-app-bar>
      <div class="expande-horizontal">
        <v-text-field
          outlined
          @keyup.enter="close()"
          v-mask="['#', '##', '#.##', '##.##', '###.##']"
          prefix="R$"
          autofocus
          v-model="get_compra.acrescimo"
        ></v-text-field>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      opened: false
    };
  },
  computed: {
    ...mapGetters(["get_compra"])
  },
  methods: {
    ...mapActions(["calcula_valor_total_da_compra"]),
    open() {
      this.opened = true;
    },
    close() {
      this.calcula_valor_total_da_compra();
      this.$forceUpdate();
      this.opened = false;
    }
  }
};
</script>
