<template>
  <v-dialog
    transition="slide-x-transition"
    fullscreen
    persistent
    class="elevation-0"
    v-model="get_modal_view_gestordepedido"
  >
    <div
      style="min-height: 100vh;background-color: rgba(0,0,0,0.6);"
      class="expande-horizontal centraliza cool-shaddow"
    >
      <v-flex xs12 md7>
        <sale-header
          :openReceiptEmitter="openReceiptEmitter"
          :printReceipt="printReceipt"
          :configureDeliveryNote="configureDeliveryNote"
          :confirmCancel="confirmCancel"
        />
        <sale-body
          :openReceiptEmitter="openReceiptEmitter"
          :printReceipt="printReceipt"
          :configureDeliveryNote="configureDeliveryNote"
          :confirmCancel="confirmCancel"
          :sendToPdv="sendToPdv"
          :initPayment="initPayment"
        />
        <delivery-note-to-print 
          v-show="false"
          id="print-note"
          class="print-unbreak"
          :lastSell="lastSell" 
          :getLoggedUser="getLoggedUser" 
        />
        <div
          v-show="showTemplate"
          id="print-html"
          style="width: 100%; padding: 0px; display: flex; flex-direction: column; flex-wrap: wrap;"
        >
          <div
            style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
          >
            <img :style="`min-height: 55px !important; max-height: 55px !important;`" :src="getLoggedUser.tenant[0].profileImage" alt="" />
            <div style="display: flex; align-items: center; flex-direction: column; padding-right: 7px; padding-left: 7px; ">
              <span class="font-head-title">
                {{ getLoggedUser.tenant[0].nome }}
              </span>
              <span class="font-head-subtitle">
                CNPJ: {{ getLoggedUser.tenant[0].cnpj }}
              </span>
              <span
                v-if="getLoggedUser.tenant[0].address"
                class="font-head-subtitle"
              >
                {{ getLoggedUser.tenant[0].address.formatted_address }}
              </span>
              <span
                class="font-head-subtitle"
                v-if="getLoggedUser.tenant[0].phone"
              >
                {{ getLoggedUser.tenant[0].phone }}
              </span>
            </div>
            <div
              class="font-head-subtitle"
              style="font-size: 9pt;display: flex; width: 15%; justify-content: center;"
            >
              {{ getLoggedUser.tenant[0].receipt_config.top_right_text }} 
            </div>
          </div>
          <div
            style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
          >
            <span class="font-title">PROPOSTA ORÇAMENTARIA</span>
          </div>
          <div
            style="padding: 6px; padding-left: 0px; padding-right: 0px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
          >
            <div style="display: flex; width: 100%;" v-if="lastSell.compra.cliente">
              <span class="font-head-title" style="margin-right: 18px;">
                Cliente:
              </span>
              <div style="padding-left: 2px; display: flex; min-width: 90%; border-bottom: 1px solid #f2f1f1;">
                <span class="font-head-subtitle">
                  {{ lastSell.compra.cliente.nome }}       
                </span>
              </div>
            </div>
            <div style="display: flex; width: 100%; margin-top: 4px;" v-if="lastSell.compra.cliente">
              <span class="font-head-title" style="margin-right: 6px;">
                Telefone:
              </span>
              <div style="padding-left: 2px; display: flex; min-width: 90%; border-bottom: 1px solid #f2f1f1;">
                <span class="font-head-subtitle">
                  {{ lastSell.compra.cliente.telefone }}
                </span>
              </div>
            </div>
          </div>
          <div
            style="display: flex; width: 100% !important; margin-top: 6px; margin-bottom: 6px; padding: 0px;"
          >
            <table
              style="witdh: 100% !important; background: #f0f0f0;"
            >
              <template>
                  <tr
                    class="tr-table"
                    style="width: 100%; background: #f7f7f7; border: 1px solid #f0f0f0;"
                  >
                    <td
                      style="padding-right: 2px; text-align: center; padding-left: 2px; color: #878686; border-right: 1px solid #D2D4CF;"
                    >
                      <span class="font-head-title">
                        QTD
                      </span>
                    </td>
                    <td
                      style="padding-right: 10px; padding-left: 10px; color: #878686; border-right: 1px solid #D2D4CF;"
                    >
                      <span class="font-head-title">
                        D E S C R I M I N A Ç Ã O
                      </span>
                    </td>
                    <td
                      style="padding-right: 10px; padding-left: 10px; color: #878686; border-right: 1px solid #D2D4CF;"
                    >
                      <span class="font-head-title">
                        MEDIDA
                      </span>
                    </td>
                    <td
                      style="padding-right: 10px; padding-left: 10px; color: #878686; border-right: 1px solid #D2D4CF;"
                    >
                      <span class="font-head-title">
                        UN
                      </span>
                    </td>
                    <td
                      style="padding-right: 10px; padding-left: 10px; color: #878686; border-right: 1px solid #D2D4CF;"
                    >
                      <span class="font-head-title">
                        VALOR
                      </span>
                    </td>
                    <td
                      style="color: #878686;padding-right: 10px; padding-left: 10px; "
                    >
                      <span class="font-head-title">
                        TOTAL
                      </span>
                    </td>
                  </tr>
                  <template
                    v-for="(item, i2) in lastSell.compra.produtos"
                  >
                    <tr
                      style="background: #cccaca; border-bottom: 3px dashed #cccaca;"
                      :key="`desc-perso-${i2}`"
                    >
                      <td
                        class="fonte fonte-micro"
                        style="color: #cccaca; padding-right: 10px; padding-left: 10px;"
                      >
                        <span class="font-head-subtitle">
                          ---
                        </span>
                      </td>
                      <td
                        class="fonte fonte-micro"
                        style="color: #cccaca;padding-right: 10px; padding-left: 10px;"
                      >
                        <span class="font-head-title">
                          {{
                            item.descricao_personalizada
                              ? item.descricao_personalizada
                              : item.nome
                          }}
                        </span>
                      </td>
                      <td style="color: #cccaca"> <span class="font-head-subtitle"> --- </span> </td>
                      <td style="color: #cccaca"> <span class="font-head-subtitle"> --- </span> </td>
                      <td style="color: #cccaca"> <span class="font-head-subtitle"> --- </span> </td>
                      <td style="color: #cccaca"> <span class="font-head-subtitle"> --- </span> </td>
                    </tr>
                    <tr
                      v-for="(ficha, i) in item.ficha_tecnica"
                      class="tr-table"
                      style="background: #f0f0f0; width: 100%;"
                      :key="`item-ficha-prod-${i}-${i2}`"
                      v-show="ficha.quantity && ficha.quantity >= 1"
                    >
                      <td
                        class="fonte fonte-micro"
                        style="color: #f7f7f7; text-align: center;"
                      >
                        <span class="font-head-title">
                          {{ ficha.quantity }}
                        </span>
                      </td>
                      <td
                        class="fonte fonte-micro"
                        style="padding-right: 10px; padding-left: 10px;"
                      >
                        <span class="font-head-subtitle">
                          {{ ficha.descricao ? ficha.descricao : "" }} -
                          {{ item.nome }}
                        </span>

                      </td>
                      <td
                        class="fonte fonte-bold"
                        style="padding-right: 10px; padding-left: 10px; text-wrap: nowrap;"
                        v-if="ficha.comprimento || ficha.largura"
                      >
                        <span class="font-head-subtitle">
                          {{ ficha.comprimento }} x {{ ficha.largura}}
                        </span>
                      </td>
                      <td
                        class="fonte fonte-bold"
                        style="color: #494949;padding-right: 10px; padding-left: 10px;"
                        v-else
                      >
                        <span class="font-head-subtitle">
                          ##
                        </span>
                      </td>
                      <td
                        class="fonte fonte-bold"
                        style="color: #494949;padding-right: 10px; padding-left: 10px;"
                      >
                        <span class="font-head-subtitle">
                          {{ verifyUnit(ficha) }}
                        </span>
                      </td>
                      <td
                        class="fonte fonte-bold"
                        style="padding-right: 10px; padding-left: 10px; text-wrap: nowrap;"
                      >
                        <span class="font-head-subtitle">
                          {{ $helper.formataSaldo(ficha.preco) }}
                        </span>
                      </td>
                      <td
                        class="fonte fonte-micro"
                        style="padding-right: 10px; padding-left: 10px;text-align: right; text-wrap: nowrap;"
                      >
                        <span class="font-head-subtitle">
                          {{
                            $helper.formataSaldo(parseFloat(ficha.total))
                          }}
                        </span>
                      </td>
                    </tr>
                  </template>
              </template>
            </table>
          </div>
          <div
            style="padding: 3px;padding-right: 6px; padding-left: 6px;display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
          >
            <table>
              <tr>
                <td style="width: 100%;"></td>
                <td style="padding-right: 6px; text-align: right;text-wrap: nowrap;">
                  <span class="font-head-title">ACABAMENTO R$</span>
                </td>
                <td style="text-align: right;padding-right: 14px;">
                  <span style="text-wrap: nowrap;" class="font-head-subtitle">
                    + {{ $helper.formataSaldo(lastSell.compra.acabamento || 0) }}
                  </span>
                </td>
              </tr>
              <tr>
                <td style="width: 100%;"></td>
                <td style="padding-right: 6px; text-align: right;text-wrap: nowrap;">
                  <span class="font-head-title">TOTAL SEM DESCONTO R$</span>
                </td>
                <td style="text-align: right;padding-right: 10px;">
                  <span style="text-wrap: nowrap;text-align: right;" class="font-head-subtitle bg-texts">
                    = {{ $helper.formataSaldo(lastSell.compra.subtotal) }}
                  </span>
                </td>
              </tr>
              <tr>
                <td style="width: 100%;"></td>
                <td style="padding-right: 6px; text-align: right;text-wrap: nowrap;border-bottom: 1px solid #c6c6c6;">
                  <span class="font-head-title">
                    {{
                      lastSell.compra.desconto_type &&
                      lastSell.compra.desconto_type === "percent"
                        ? `${lastSell.compra.desconto_applyed}% DE`
                        : ""
                    }} DESCONTO, R$</span>
                </td>
                <td style="text-align: right;padding-right: 14px;border-bottom: 1px solid #c6c6c6;">
                  <span style="text-wrap: nowrap;text-align: right;" class="font-head-subtitle">
                    - {{ $helper.formataSaldo(lastSell.compra.desconto || 0) }}
                  </span>
                </td>
              </tr>
              <tr>
                <td style="width: 100%;"></td>
                <td style="padding-right: 6px; text-align: right; text-wrap: nowrap;">
                  <span class="font-head-title">TOTAL COM DESCONTO R$</span>
                </td>
                <td style="text-align: right;padding: 10px;">
                  <span style="text-wrap: nowrap;text-align: right;" class="font-head-subtitle bg-texts">
                    = {{ $helper.formataSaldo(lastSell.compra.preco_total) }}
                  </span>
                </td>
              </tr>
            </table>
          </div>

          <div
            style="color: #bebcbc; margin-top: 36px; display: flex; width: 100%; justify-content: center;"
          >
            _________________________________________________________________
          </div>
          <div
            style="display: flex; width: 100%; justify-content: center;"
          >
            <span class="font-head-title">
              AUTORIZO
            </span>
          </div>
          <div
            style="margin-bottom: 12px; margin-top: 4px; flex-direction: column; display: flex; width: 100%;"
          >
            <div style="display: flex; width: 100%; justify-content: center;">
              <span v-if="getLoggedUser.tenant[0].receipt_config && getLoggedUser.tenant[0].receipt_config.show_header_text" class="font-head-subtitle">
                {{ getLoggedUser.tenant[0].receipt_config.show_header_text.text }}
              </span>
            </div>
            <table>
              <tr>
                <th style="background: #e2e0e0;border-bottom: 1px solid #e2e0e0 !important;">DATA DE EMISSÃO</th>
                <th style="background: #e2e0e0;border-bottom: 1px solid #e2e0e0 !important;">OBSERVAÇÕES</th>
                <th style="background: #e2e0e0;border-bottom: 1px solid #e2e0e0 !important;">Nº DO DOCUMENTO</th>
              </tr>

              <tr>
                <td style="border-bottom: 1px solid #e2e0e0 !important;">
                  <span style="padding: 6px; display: flex; width: 100%; justify-content: center;" class="font-head-subtitle">
                    {{ moment().format("DD/MM/YYYY HH:mm") }}
                  </span>
                </td>
                <td style="border-bottom: 1px solid #e2e0e0 !important;">
                  <span style="padding: 6px; display: flex; width: 100%; justify-content: center;" class="font-head-subtitle">{{ getLoggedUser.tenant[0].receipt_config.short_obs }}</span>
                </td>
                <td style="max-width: 70px; border-bottom: 1px solid #e2e0e0 !important;">
                  <span style="padding: 6px; display: flex; width: 100%; justify-content: center;" class="font-head-subtitle">{{ lastSell.id_transacao }}</span>
                </td>
              </tr>

              <tr>
                <th style="background: #e2e0e0;border-bottom: 1px solid #e2e0e0 !important;">VALIDADE DA PROPOSTA</th>
                <th style="padding-left: 6px;background: #fff;">
                  <span style="display: flex; width: 100%;" class="font-head-subtitle">
                    Atenciosamente,
                  </span>
                </th>
                <th>*</th>
              </tr>

              <tr style="margin-botom: 0px; padding-bottom: 0px;">
                <td style="border-bottom: 1px solid #e2e0e0 !important;">
                  <span style="display: flex; width: 100%; justify-content: center;" class="font-head-subtitle">
                    {{ moment().add(getLoggedUser.tenant[0].receipt_config.validate_proposal_in_days ? getLoggedUser.tenant[0].receipt_config.validate_proposal_in_days : 7, 'days').format("DD/MM/YYYY HH:mm") }}
                  </span>
                </td>
                <td style="border-bottom: 1px solid #e2e0e0 !important;">
                  <span style="padding-left: 6px; display: flex; width: 100%; align-items: center;" class="font-head-subtitle">
                    <span>{{ this.getLoggedUser.nome }}</span>
                  </span>
                </td>
                <td style="max-width: 70px; border-bottom: 1px solid #e2e0e0 !important;">
                  <span style="font-size: 9pt; display: flex; width: 100%; justify-content: center;" class="font-head-subtitle">
                    {{ getLoggedUser.tenant[0].receipt_config.bottom_right_text }}
                  </span>
                </td>
              </tr>
            </table>
            <div style="display: flex; width: 100%; justify-content: center;">
              <span v-if="getLoggedUser.tenant[0].receipt_config && getLoggedUser.tenant[0].receipt_config.show_footer_text" class="font-head-subtitle">
                {{ getLoggedUser.tenant[0].receipt_config.show_footer_text.text }}
              </span>
            </div>
          </div>
        </div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          filename="recibo"
          :manual-pagination="false"
          :paginate-elements-by-height="1200"
          pdf-format="a4"
          :pdf-quality="1"
          pdf-content-width="800"
          pdf-orientation="portrait"
          @progress="() => {}"
          @hasStartedGeneration="() => {}"
          @hasDownloaded="() => {}"
          ref="emitterReceipt"
        >
          <div slot="pdf-content">
            <section class="pdf-item fonte">
              <div
                id="print-receipt"
                style="width: 95%; display: flex; flex-direction: column;"
              >
                <div
                  style="min-height: 50%; display: flex; padding-right: 30px !important; flex-direction: column; flex-wrap: nowrap; max-width: 96%;"
                >
                  <div
                    style="border-radius: 6px; border: 12px solid #e5e5e5; margin-bottom: 12px; display: flex; justify-content: space-between; align-items: center; flex-direction: row; flex-wrap: nowrap; width: 100%;"
                  >
                    <img :style="`min-height: 35px !important; max-height: 35px !important;`" :src="getLoggedUser.tenant[0].profileImage" alt="" />
                    <div style="display: flex; align-items: center; flex-direction: column; padding-right: 7px; padding-left: 7px; ">
                      <span class="font-head-title">
                        {{ getLoggedUser.tenant[0].nome }}
                      </span>
                      <span class="font-head-subtitle">
                        CNPJ: {{ getLoggedUser.tenant[0].cnpj }}
                      </span>
                      <span
                        v-if="getLoggedUser.tenant[0].address"
                        class="font-head-subtitle"
                      >
                        {{ getLoggedUser.tenant[0].address.formatted_address }}
                      </span>
                      <span
                        class="font-head-subtitle"
                        v-if="getLoggedUser.tenant[0].phone"
                      >
                        {{ getLoggedUser.tenant[0].phone }}
                      </span>
                    </div>
                    <div
                      class="font-head-subtitle"
                      style="border-left: 12px solid #e5e5e5; padding: 3px; flex-direction: column;display: flex; width: 20%; align-items: center; justify-content: space-between;"
                    >
                      <div style="flex-wrap: nowrap; display: flex; justify-content: space-between;">
                        <span class="font-head-title" style="margin-right: 3px;">RECIBO </span>
                        <span class="font-head-subtitle" style="font-size: 9pt !important;">Nº {{ get_gestordepedido.id_transacao }}-{{ emit_receipt_form.order }} </span>
                      </div>
                      <span class="font-head-title">
                        {{
                          $helper.formataSaldo(
                            emit_receipt_form.value.replace(",", ".")
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <div
                      style="border-radius: 6px; border: 12px solid #e5e5e5; padding: 12px; display: flex; min-width: 97%; max-width: 97%; flex-direction: column;"
                    >
                    <div style="margin-bottom: 12px;display: flex; flex-direction: column;">
                      <span class="font-head-subtitle" style="font-size: 9pt;" >Recebi(emos) de:</span>
                      <span class="font-head-title">{{ emit_receipt_form.client }}</span>
                    </div>
                    <div style="margin-bottom: 12px;display: flex; flex-direction: column;">
                      <span class="font-head-subtitle" style="font-size: 9pt;" >A quantia de:</span>
                      <span class="font-head-title">{{ emit_receipt_form.value_extense }}</span>
                    </div>
                    <div style="margin-bottom: 12px;display: flex; flex-direction: column;">
                      <span class="font-head-subtitle" style="font-size: 9pt;" >Referente a:</span>
                      <span class="font-head-title">{{ emit_receipt_form.referrer_to }}</span>
                    </div>
                  </div>
                  <div
                    style="width: 100%; margin-top: 12px !important; flex-wrap: nowrap; margin-bottom: 30px; padding-bottom: 30px; border-bottom: 1px dashed #333; display: flex; flex-direction: column; flex-wrap: wrap;"
                  >   
                    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; background: #e5e5e5; border-radius: 6px; padding: 12px; padding-top: 30px; !important">
                      <div style="margin-top: 20px;">
                        __________________________________________________
                      </div>
                      <div>
                        Assinatura
                      </div>
                      <span style="margin-top: 18px !important;" class="font-head-title">
                        {{ $moment().format('DD/MM/YYYY') }}
                      </span>
                      <span style="margi-top: 3px; font-size: 9pt;" class="font-head-subtitle">
                        {{ emit_receipt_form.data }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style="min-height: 50%; display: flex; padding-right: 30px !important; flex-direction: column; flex-wrap: nowrap; max-width: 96%;"
                >
                  <div
                    style="border-radius: 6px; border: 12px solid #e5e5e5; margin-bottom: 12px; display: flex; justify-content: space-between; align-items: center; flex-direction: row; flex-wrap: nowrap; width: 100%;"
                  >
                    <img :style="`min-height: 35px !important; max-height: 35px !important;`" :src="getLoggedUser.tenant[0].profileImage" alt="" />
                    <div style="display: flex; align-items: center; flex-direction: column; padding-right: 7px; padding-left: 7px; ">
                      <span class="font-head-title">
                        {{ getLoggedUser.tenant[0].nome }}
                      </span>
                      <span class="font-head-subtitle">
                        CNPJ: {{ getLoggedUser.tenant[0].cnpj }}
                      </span>
                      <span
                        v-if="getLoggedUser.tenant[0].address"
                        class="font-head-subtitle"
                      >
                        {{ getLoggedUser.tenant[0].address.formatted_address }}
                      </span>
                      <span
                        class="font-head-subtitle"
                        v-if="getLoggedUser.tenant[0].phone"
                      >
                        {{ getLoggedUser.tenant[0].phone }}
                      </span>
                    </div>
                    <div
                      class="font-head-subtitle"
                      style="border-left: 12px solid #e5e5e5; padding: 3px; flex-direction: column;display: flex; width: 20%; align-items: center; justify-content: space-between;"
                    >
                      <div style="flex-wrap: nowrap; display: flex; justify-content: space-between;">
                        <span class="font-head-title" style="margin-right: 3px;">RECIBO </span>
                        <span class="font-head-subtitle" style="font-size: 9pt !important;">Nº {{ get_gestordepedido.id_transacao }}-{{ emit_receipt_form.order }} </span>
                      </div>
                      <span class="font-head-title">
                        {{
                          $helper.formataSaldo(
                            emit_receipt_form.value.replace(",", ".")
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <div
                      style="border-radius: 6px; border: 12px solid #e5e5e5; padding: 12px; display: flex; min-width: 97%; max-width: 97%; flex-direction: column;"
                    >
                    <div style="margin-bottom: 12px;display: flex; flex-direction: column;">
                      <span class="font-head-subtitle" style="font-size: 9pt;" >Recebi(emos) de:</span>
                      <span class="font-head-title">{{ emit_receipt_form.client }}</span>
                    </div>
                    <div style="margin-bottom: 12px;display: flex; flex-direction: column;">
                      <span class="font-head-subtitle" style="font-size: 9pt;" >A quantia de:</span>
                      <span class="font-head-title">{{ emit_receipt_form.value_extense }}</span>
                    </div>
                    <div style="margin-bottom: 12px;display: flex; flex-direction: column;">
                      <span class="font-head-subtitle" style="font-size: 9pt;" >Referente a:</span>
                      <span class="font-head-title">{{ emit_receipt_form.referrer_to }}</span>
                    </div>
                  </div>
                  <div
                    style="width: 100%; margin-top: 12px !important; flex-wrap: nowrap; display: flex; flex-direction: column; flex-wrap: wrap;"
                  >   
                    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; background: #e5e5e5; border-radius: 6px; padding: 12px; padding-top: 30px; !important">
                      <div style="margin-top: 20px;">
                        __________________________________________________
                      </div>
                      <div>
                        Assinatura
                      </div>
                      <span style="margin-top: 18px !important;" class="font-head-title">
                        {{ $moment().format('DD/MM/YYYY') }}
                      </span>
                      <span style="margi-top: 3px; font-size: 9pt;" class="font-head-subtitle">
                        {{ emit_receipt_form.data }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </vue-html2pdf>
      </v-flex>
    </div>
    <ModalFechamento
      :payment_sys_config="payment_sys_config"
      :updatePayment="updatePayment"
      ref="modalFechamento"
    />
    <v-dialog v-model="emit_receipt_dialog" width="600">
      <v-card class="expande-horizontal wrap">
        <v-flex xs12>
          <v-list>
            <v-list-item>
              <v-list-item-avatar
                :color="$theme.secondary"
                @click="closeReceiptEmitter"
              >
                <v-icon color="#fff">
                  mdi-arrow-left
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte">
                  Informações do recibo
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-flex>
        <v-flex xs12>
          <v-form ref="formReceipt">
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="cliente"
                v-model="emit_receipt_form.client"
                outlined
                dense
                hide-details
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="Valor"
                v-mask="['#,##', '##,##', '###,##', '####,##', '#####,##']"
                v-model="emit_receipt_form.value"
                outlined
                dense
                hide-details
                autofocus
                @input="formatNumberToExtense"
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="Valor por extenso"
                v-model="emit_receipt_form.value_extense"
                outlined
                dense
                hide-details
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="Referente a"
                v-model="emit_receipt_form.referrer_to"
                outlined
                dense
                hide-details
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pt" xs12>
              <v-btn :color="$theme.secondary" dark @click="emitReceipt">
                Emitir
              </v-btn>
            </v-flex>
          </v-form>
        </v-flex>
      </v-card>
    </v-dialog>
    <ModalChangeAddressFromViewSale
      :printDeliveryNote="printDeliveryNote"
      ref="modalChangeDelivery"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalFechamento from "../components/SaleStages/ModalFechamento.vue";
import ModalChangeAddressFromViewSale from "@/apps/27#Caixas/views/components/ModalChangeDeliveryFromViewSale.vue";
import printHtmlToPDF from "print-html-to-pdf";
import Moment from "moment";
import QrcodeVue from "qrcode.vue";
import extenso from "extenso";
import CosmosPluginStore from "@/apps/CosmosPluginStore/index.vue";
import SaleHeader from "@/apps/GestorDePedido/components/viewSale/saleHeader.vue";
import SaleBody from "@/apps/GestorDePedido/components/viewSale/saleBody.vue";
import deliveryNoteToPrint from "@/apps/GestorDePedido/components/viewSale/documentToPrint/deliveryNote.vue";
import { EventBus } from "@/main.js";

export default {
  props: {
    payment_sys_config: {
      type: Object,
      default: () => {}
    },
    receipt: {
      type: Object,
      default: () => {
        return {
          show_my_address: { active: true },
          show_my_logo: { active: true },
          show_my_qrcode: { active: true },
          show_client_details: { active: true },
          show_header_text: { active: true, text: "" },
          show_footer_text: { active: true, text: "" }
        };
      }
    }
  },
  data() {
    return {
      tab: 0,
      showTemplate: false,
      moment: Moment,
      printHtmlToPDF: printHtmlToPDF,
      lastSell: {
        compra: {
          preco_total: 0,
          cliente: {},
          address: {}
        },
        caixa: {
          funcionario: {}
        }
      },
      emit_receipt_form: {
        client: {},
        vendedor: this.getLoggedUser,
        value: ""
      },
      emit_receipt_dialog: false,
      manual_select_status: false
    };
  },
  components: {
    ModalFechamento,
    QrcodeVue,
    ModalChangeAddressFromViewSale,
    CosmosPluginStore,
    SaleHeader,
    SaleBody,
    deliveryNoteToPrint
  },
  computed: {
    ...mapGetters([
      "get_gestordepedidos",
      "get_gestordepedido",
      "get_modal_view_gestordepedido",
      "getLoggedUser"
    ]),
    logoBase64() {
      return this.getLoggedUser.tenant[0].profileImage;
    },
    nomeCliente() {
      if (
        this.get_gestordepedido.compra.cliente &&
        this.get_gestordepedido.compra.cliente.nome
      ) {
        return this.get_gestordepedido.compra.cliente.nome;
      } else {
        return "Cliente não informado!";
      }
    }
  },
  filters: {
    getPriceFilter(val) {
      let price = 0;
      if (val.tipo_de_preco === "unico") {
        price = val.preco_unico;
        return price;
      } else if (val.tipo_de_preco === "multiplo") {
        const precoEscolhido = val.preco_multiplo.filter(preco => preco.marked);
        price = precoEscolhido[0].preco;
        return price;
      }
      return price;
    }
  },
  methods: {
    verifyUnit(item) {
      if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) <= 0.1
      ) {
        return "ML";
      } else if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) > 0.1
      ) {
        return "M²";
      } else if (item.type === "Acabamento") {
        return "UND";
      }
    },
    confirmCancel() {
      this.createConfirmAction({
        message: "Deseja cancelar esta venda?",
        icone: "mdi-close-circle",
        isScoped: true,
        action: () => this.cancel_sale(),
        action_value: "ok"
      });
    },
    sendToPdv(id) {
      this.fecha_modal_view_gestordepedido();
      this.$router.push(`/atendimento?t_id=${id}`);
    },
    updatePayment() {
      this.$store.dispatch("enableLoading");
      this.$run("caixas/update-payment", this.get_gestordepedido)
        .then(res => {
          this.valueToPayInSelectedMethod = "";
          this.type = "";
          this.$store.dispatch("disableLoading");
          this.$store.commit("set_gestordepedido", res.data);
          if (res.data.status_do_pagamento === "pago") {
            this.step = 3;
          } else {
            this.step = 1;
          }
          this.$forceUpdate();
        })
        .catch(e => {
          this.$store.dispatch("disableLoading");
          console.log(e);
        });
    },
    initPayment() {
      //validations here pls
      if (this.get_gestordepedido.compra.produtos.length) {
        if (!this.$refs.modalFechamento.modal) {
          this.$refs.modalFechamento.open();
        }
      } else {
        this.createGlobalMessage({
          type: "error",
          message: "Não é possível efetuar uma venda sem produtos!",
          timeout: 6000
        });
      }
    },
    ...mapActions([
      "criar_gestordepedido",
      "createConfirmAction",
      "atualizar_gestordepedido",
      "listar_gestordepedidos",
      "fecha_modal_view_gestordepedido"
    ]),
    update_status(index, status_to_find_next) {
      let status;

      if (status_to_find_next) {
        this.status_type[this.get_gestordepedido.compra.tipo].map((s, i) => {
          if (status_to_find_next === s.value) {
            status = this.status_type[this.get_gestordepedido.compra.tipo][
              i + 1
            ];
          }
        });
      } else {
        status = this.status_type[this.get_gestordepedido.compra.tipo][index];
      }
      // console.log("sss", status);
      this.$run("caixas/atualizar-status-do-pedido", {
        ...this.get_gestordepedido,
        novo_status: status.value
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `Status do pedido atualizado para ${status.text}`,
            timeout: 6000
          });
          this.get_gestordepedido.status_do_pedido = status.value;
          this.get_gestordepedido.historico_de_status.push({
            status: status.value,
            data: new Date()
          });
          const docs = this.get_gestordepedidos.docs;
          const updatedDoc = this.get_gestordepedido;
          const newDocs = [];
          docs.map(docTransaction => {
            if (docTransaction._id === updatedDoc._id) {
              newDocs.push(this.get_gestordepedido);
            } else {
              newDocs.push(docTransaction);
            }
          });
          this.$store.commit("set_gestordepedidos", {
            ...this.get_gestordepedidos,
            docs: newDocs
          });
          // this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    },
    refuse_sale() {
      this.$run("caixas/atualizar-status-do-pedido", {
        ...this.get_gestordepedido,
        novo_status: "recusada"
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `A venda foi recusada`,
            timeout: 6000
          });
          this.get_gestordepedido.status_do_pedido = "recusada";
          this.get_gestordepedido.historico_de_status.push({
            status: "recusada",
            data: new Date()
          });
          // this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    },
    remove_sale_from_localstorage(to_remove_id) {
      if (!localStorage.gestordepedidos) return;
      const docs = JSON.parse(localStorage.gestordepedidos);
      const newDocs = docs.docs.filter(docTransaction => {
        return docTransaction._id !== to_remove_id;
      });
      localStorage.setItem(
        "gestordepedidos",
        JSON.stringify({ docs: newDocs })
      );
      this.listar_gestordepedidos();
    },
    cancel_sale() {
      this.$run("caixas/cancel-sale", {
        ...this.get_gestordepedido,
        novo_status: "cancelada"
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `A venda foi cancelada`,
            timeout: 6000
          });
          // this.get_gestordepedido.status_do_pedido = "cancelada";
          // this.get_gestordepedido.historico_de_status.push({
          //   status: "cancelada",
          //   data: new Date()
          // });
          this.fecha_modal_view_gestordepedido();
          this.remove_sale_from_localstorage(this.get_gestordepedido._id);
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    },
    printReceipt(compra) {
      // this.in_printing = true;
      this.showTemplate = true;
      this.lastSell = compra;
      var janelaImprimir = window.open("", "", "width=600,height=600");
      setTimeout(() => {
        janelaImprimir.document.open();
        janelaImprimir.document.write(
          "<html><head><title>Proposta Orçamentária</title>"
        );
        janelaImprimir.document.write('<meta charset="utf-8">')
        const linkPreconnect = document.createElement("link");
        linkPreconnect.rel = "stylesheet";
        linkPreconnect.type = "text/css";
        linkPreconnect.href = "https://fonts.googleapis.com";
        linkPreconnect.crossOrigin = true;

        const linkPreconnect2 = document.createElement("link");
        linkPreconnect2.rel = "stylesheet";
        linkPreconnect2.type = "text/css";
        linkPreconnect2.href = "https://fonts.gstatic.com";

        const linkFonts = document.createElement("link");
        linkFonts.rel = "stylesheet";
        linkFonts.type = "text/css";
        linkFonts.href = "https://fonts.googleapis.com/css2?family=Arsenal:wght@400;700&family=Chakra+Petch:wght@600&display=swap"
        linkFonts.crossOrigin = true;

        janelaImprimir.document.head.appendChild(linkPreconnect);
        janelaImprimir.document.head.appendChild(linkPreconnect2);
        janelaImprimir.document.head.appendChild(linkFonts);

        janelaImprimir.document.write("<style>");
        janelaImprimir.document.write("table { width: 100%; }");
        janelaImprimir.document.write("body { -webkit-print-color-adjust: exact; }");
        janelaImprimir.document.write(".tr-table { border: 1px solid #333; margin: 0px; }");
        janelaImprimir.document.write(".bg-texts { font-weight: bold !important; background-color: #302b63 !important; border-radius: 3px; padding: 3px; padding-right: 6px; padding-left: 6px; color: #fff !important; }");
        janelaImprimir.document.write(".font-head-title { font-size: 14pt; font-weight: bold; font-family: 'Arsenal', sans-serif; color: #333; }");
        janelaImprimir.document.write(".font-head-subtitle { font-size: 12pt; font-family: 'Arsenal', sans-serif; color: #666; }");
        janelaImprimir.document.write(".font-title { font-size: 20pt; font-weight: bold; font-family: 'Chakra Petch', sans-serif; color: #333; }");
        janelaImprimir.document.write("@page { size: A4; margin: 0.5cm; }");
        janelaImprimir.document.write("@media print { .bg-texts { background-color: #302b63 !important; } }");
        janelaImprimir.document.write("</style>");
        janelaImprimir.document.write(
          "</head><body>"
        );
        janelaImprimir.document.write('<div id="imprimir-conteudo">');
        janelaImprimir.document.write(
          document.getElementById("print-html").innerHTML
        );
        janelaImprimir.document.write("</div>");
        janelaImprimir.document.write("</body></html>");
        janelaImprimir.document.close();
        janelaImprimir.focus(); // necessary for IE >= 10*/
        janelaImprimir.onload = () => {
          janelaImprimir.print();
          if (this.$vuetify.breakpoint.lgAndUp) {
            janelaImprimir.close();
            this.showTemplate = false;
          }
        };
      }, 200);
    },
    configureDeliveryNote() {
      this.$refs.modalChangeDelivery.open();
    },
    printDeliveryNote(compra) {
      this.lastSell = compra;
      console.log("compraaa", compra);
      var janelaImprimir = window.open("", "", "width=600,height=600");
      setTimeout(() => {
        janelaImprimir.document.open();
        janelaImprimir.document.write(
          "<html><head><title>Nota de entrega</title>"
        );
        janelaImprimir.document.write('<meta charset="utf-8">')
        const linkPreconnect = document.createElement("link");
        linkPreconnect.rel = "stylesheet";
        linkPreconnect.type = "text/css";
        linkPreconnect.href = "https://fonts.googleapis.com";
        linkPreconnect.crossOrigin = true;

        const linkPreconnect2 = document.createElement("link");
        linkPreconnect2.rel = "stylesheet";
        linkPreconnect2.type = "text/css";
        linkPreconnect2.href = "https://fonts.gstatic.com";

        const linkFonts = document.createElement("link");
        linkFonts.rel = "stylesheet";
        linkFonts.type = "text/css";
        linkFonts.href = "https://fonts.googleapis.com/css2?family=Arsenal:wght@400;700&family=Chakra+Petch:wght@600&display=swap"
        linkFonts.crossOrigin = true;

        janelaImprimir.document.head.appendChild(linkPreconnect);
        janelaImprimir.document.head.appendChild(linkPreconnect2);
        janelaImprimir.document.head.appendChild(linkFonts);

        janelaImprimir.document.write("<style>");
        janelaImprimir.document.write("body { -webkit-print-color-adjust: exact; margin: 0px; padding:0px; width: 100%; display: flex; flex-direction: row; flex-wrap: nowrap; }");
        janelaImprimir.document.write(".font-head-title { font-size: 14pt; font-weight: bold; font-family: 'Arsenal', sans-serif; color: #333; }");
        janelaImprimir.document.write(".font-head-subtitle { font-size: 12pt; font-family: 'Arsenal', sans-serif; color: #666; }");
        janelaImprimir.document.write(".font-title { font-size: 20pt; font-weight: bold; font-family: 'Chakra Petch', sans-serif; color: #333; }");
        janelaImprimir.document.write(".page-unbreak { page-break-after: always; background: #333; width: 1100px; display: flex;  flex-wrap: nowrap !important; }");
        janelaImprimir.document.write("@page { size: landscape; margin: 0.5cm; display: flex; flex-wrap: nowrap; }");
        janelaImprimir.document.write("</style>");
        janelaImprimir.document.write(
          "</head><body>"
        );
        janelaImprimir.document.write(
          document.getElementById("print-note").innerHTML
        );
        janelaImprimir.document.write("</body></html>");
        janelaImprimir.document.close();
        janelaImprimir.focus(); // necessary for IE >= 10*/
        janelaImprimir.onload = () => {
          janelaImprimir.print();
          if (this.$vuetify.breakpoint.lgAndUp) {
            janelaImprimir.close();
          } else {
            janelaImprimir.onafterprint = () => {
              janelaImprimir.close();
            };
          }
        }
      }, 300);
    },
    openModalAddProduct(item, index) {
      console.log("abre modal product", item);
      return;
    },
    abre_modal_view_caixa(item) {
      console.log("abre modal view caixa", item);
      return;
    },
    openReceiptEmitter() {
      this.emit_receipt_dialog = true;
      this.emit_receipt_form = {
        client: this.get_gestordepedido.compra.cliente.nome,
        value: "",
        vendedor: this.getLoggedUser,
      };
    },
    closeReceiptEmitter() {
      this.emit_receipt_dialog = false;
    },
    formatNumberToExtense() {
      const value = this.emit_receipt_form.value || "0";
      const value_replaced = parseInt(value.replace(",", "."));
      this.emit_receipt_form.value_extense = extenso(value_replaced, {
        mode: "currency",
        currency: { type: "BRL" }
      });
      this.$forceUpdate();
    },
    emitReceipt() {
      if (this.$refs.formReceipt.validate()) {
        this.getNextOrderReceipt();
        var janelaImprimir = window.open("", "", "width=600,height=600");
        setTimeout(() => {
          janelaImprimir.document.open();
          janelaImprimir.document.write(
            "<html><head><title>Recibo</title>"
          );
          janelaImprimir.document.write('<meta charset="utf-8">')
          const linkPreconnect = document.createElement("link");
          linkPreconnect.rel = "stylesheet";
          linkPreconnect.type = "text/css";
          linkPreconnect.href = "https://fonts.googleapis.com";
          linkPreconnect.crossOrigin = true;

          const linkPreconnect2 = document.createElement("link");
          linkPreconnect2.rel = "stylesheet";
          linkPreconnect2.type = "text/css";
          linkPreconnect2.href = "https://fonts.gstatic.com";

          const linkFonts = document.createElement("link");
          linkFonts.rel = "stylesheet";
          linkFonts.type = "text/css";
          linkFonts.href = "https://fonts.googleapis.com/css2?family=Arsenal:wght@400;700&family=Chakra+Petch:wght@600&display=swap"
          linkFonts.crossOrigin = true;

          janelaImprimir.document.head.appendChild(linkPreconnect);
          janelaImprimir.document.head.appendChild(linkPreconnect2);
          janelaImprimir.document.head.appendChild(linkFonts);
          janelaImprimir.document.write("<style>");
          janelaImprimir.document.write("body { -webkit-print-color-adjust: exact; width: 100%; display: flex; flex-direction: column; margin: 0px; padding: 0px; }");
          janelaImprimir.document.write(".page-unbreak { page-break-after: always; background: #333;width: 1400px; display: flex;  flex-wrap: nowrap !important; }");
          janelaImprimir.document.write(".font-head-title { font-size: 14pt; font-weight: bold; font-family: 'Arsenal', sans-serif; color: #333; }");
          janelaImprimir.document.write(".font-head-subtitle { font-size: 12pt; font-family: 'Arsenal', sans-serif; color: #666; }");
          janelaImprimir.document.write(".font-title { font-size: 20pt; font-weight: bold; font-family: 'Chakra Petch', sans-serif; color: #333; }");
          janelaImprimir.document.write("@page { size: a4; margin: 0.5cm; }");
          janelaImprimir.document.write("</style>");
          janelaImprimir.document.write(
            "</head><body>"
          );
          janelaImprimir.document.write(
            document.getElementById("print-receipt").innerHTML
          );
          janelaImprimir.document.write("</body></html>");
          janelaImprimir.document.close();
          janelaImprimir.focus(); // necessary for IE >= 10*/
          janelaImprimir.onload = () => {
            janelaImprimir.print();
            if (this.$vuetify.breakpoint.lgAndUp) {
              janelaImprimir.close();
            }
          };
        }, 200);
        this.saveSale();
      }
    },
    getNextOrderReceipt() {
      const receipts = this.get_gestordepedido.compra.receipts
        ? this.get_gestordepedido.compra.receipts
        : [];
      if (receipts.length === 0) {
        let compra = this.get_gestordepedido.compra;
        compra.receipts = [];
        this.emit_receipt_form.order = receipts.length + 1;
        this.emit_receipt_form.data = this.$moment().format("LLLL");
        compra.receipts.push(this.emit_receipt_form);
        this.$store.commit("set_gestordepedido", {
          ...this.get_gestordepedido,
          compra
        });
      }
      if (receipts.length >= 1) {
        let compra = this.get_gestordepedido.compra;
        this.emit_receipt_form.order = receipts.length + 1;
        this.emit_receipt_form.data = this.$moment().format("LLLL");
        compra.receipts.push(this.emit_receipt_form);
        this.$store.commit("set_gestordepedido", {
          ...this.get_gestordepedido,
          compra
        });
      }
    },
    saveSale() {
      setTimeout(() => {
        this.atualizar_gestordepedido();
      }, 300);
    }
  },
};
</script>

<style>
.border-view-sale {
  border: 1px solid #dfdfdf;
  border-radius: 6px;
}
.roller {
  font-family: "Prompt", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 13px;
  color: rgb(69, 200, 111);
}
.item-current-status {
  background: #1173ff;
  color: #fff !important;
}
.border-receipt {
  border: 10px solid #f2f2f2;
  border-radius: 5px;
}
</style>
