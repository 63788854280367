<template>
  <v-dialog
    fullscreen
    persistent
    v-model="formEstoqueDialog"
    style="height: 100vh; background: rgba(215, 215, 215, 0.1);
backdrop-filter: blur(20px);"
  >
    <div
      class="expande-horizontal px-3 centraliza"
      style="height: 100vh; background: rgba(215, 215, 215, 0.1);
backdrop-filter: blur(20px);"
    >
      <v-card width="500" class="expande-horizontal wrap">
        <v-flex xs12>
          <v-list nav color="transparent" class="pa-0 ma-0 pt-0 pb-2">
            <v-list-item class="fonte pt-2">
              <v-avatar color="#333" dark class="mr-2">
                <v-icon :color="$theme.primary">mdi-cash-register</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="">
                  {{
                    get_estoque.produto
                      ? get_estoque.produto.nome
                      : "Escolha o produto"
                  }}
                </v-list-item-title>
                <span class="fonte ml-0 fonteMini grey--text">
                  Informe a quantidade abaixo e o preço de compra.
                </span>
              </v-list-item-content>
              <v-list-item-action>
                <v-avatar
                  size="31"
                  @click="formEstoqueDialog = false"
                  style="cursor: pointer;"
                  :color="$theme.primary"
                  dark
                  class="mr-2"
                >
                  <v-icon color="black">
                    mdi-close
                  </v-icon>
                </v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-flex>
        <div class="expande-horizontal pa-3 wrap">
          <v-flex v-if="!get_estoque.produto" xs12>
            <div class="expande-horizontal">
              <v-autocomplete
                label="Informe o produto"
                @input="changeProduct"
                :items="materials"
                return-object
                item-text="nome"
                v-model="get_estoque.produto"
              ></v-autocomplete>
            </div>
          </v-flex>
          <v-flex v-if="get_estoque.produto" xs12>
            <div class="fonte expande-horizontal centraliza">
              <h1 class="fonte-big">
                {{ get_estoque.quantidade }}
              </h1>
              <span class="pt-2" v-if="get_estoque.unidade_de_medida">{{
                get_estoque.unidade_de_medida.valor
              }}</span>
            </div>
          </v-flex>
          <v-flex v-if="get_estoque.produto" xs12>
            <v-form ref="formEstoque">
              <div class="expande-horizontal wrap">
                <v-flex class="pa-1" xs12>
                  <v-text-field
                    filled
                    autofocus
                    ref="qtdEstoque"
                    label="Quantidade"
                    background-color="#f2f2f2"
                    placeholder="ex: 100"
                    type="Number"
                    :color="$theme.primary"
                    hide-details
                    :rules="[
                      v => !!v || 'Campo obrigatório',
                      v => v > 0 || 'Valor deve ser maior que zero'
                    ]"
                    v-model="get_estoque.quantidade"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  v-if="
                    get_estoque.unidade_de_medida &&
                      get_estoque.unidade_de_medida.nome === 'CAIXA'
                  "
                  class="pa-1 pt-0"
                  xs12
                >
                  <span class="fonte-italica fonte-subtitulo">
                    Quantidade de itens por caixa
                  </span>
                  <v-text-field
                    solo
                    flat
                    dense
                    class="fonte-subtitulo fonte-italica"
                    background-color="#f2f2f2"
                    placeholder="ex: 10"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    type="Number"
                    v-model="get_estoque.unidade_de_medida.divisor"
                  ></v-text-field>
                </v-flex>
                <v-flex v-if="get_estoque.type == 'in'" class="pa-1 pt-3" xs12>
                  <v-text-field
                    filled
                    prefix="R$"
                    :color="$theme.primary"
                    :label="
                      `Preço pago por ${get_estoque.unidade_de_medida.nome}`
                    "
                    background-color="#f2f2f2"
                    placeholder="ex: 10.50"
                    @keyup.enter.prevent="adicionarEstoque"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    v-mask="['#.##', '##.##', '###.##', '####.##']"
                    v-model="get_estoque.preco_de_compra"
                  ></v-text-field>
                </v-flex>
                <v-flex class="pa-1 pt-3" xs12>
                  <div class="expande-horizontal">
                    <v-autocomplete
                      filled
                      label="Responsável"
                      :items="get_funcionarios.docs"
                      return-object
                      item-text="nome"
                      v-model="request_user"
                    ></v-autocomplete>
                  </div>
                </v-flex>
              </div>
            </v-form>
          </v-flex>
          <v-flex v-if="get_estoque.produto" xs12>
            <div class="expande-horizontal">
              <v-btn
                @click="adicionarEstoque"
                :color="$theme.primary"
                dark
                block
                class="fonte black--text"
                >Finalizar</v-btn
              >
            </div>
          </v-flex>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    materials: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formEstoqueDialog: false,
      unidades_de_medida: [
        {
          nome: "Kilo(KG)",
          valor: "KG",
          divisor: 1000
        },
        {
          nome: "GRAMA(g)",
          valor: "g",
          divisor: 1
        },
        {
          nome: "Litro (L)",
          valor: "L",
          divisor: 1000
        },
        {
          nome: "Mililitro (ML)",
          valor: "ML",
          divisor: 1000
        },
        {
          nome: "UNIDADE",
          valor: "UN",
          divisor: 1
        },
        {
          nome: "CAIXA",
          valor: "CX",
          divisor: 1
        }
      ],
      unidades_de_medida_base: [
        {
          nome: "Kilo(KG)",
          valor: "KG",
          divisor: 1000
        },
        {
          nome: "Litro (L)",
          valor: "L",
          divisor: 1000
        },
        {
          nome: "UNIDADE",
          valor: "UN",
          divisor: 1
        },
        {
          nome: "Metro Quadrado",
          valor: "M2",
          divisor: 100
        }
        // {
        //   nome: "CAIXA",
        //   valor: "CX"
        // },
        // {
        //   nome: "PACOTE",
        //   valor: "PCT",
        //   divisor: 1
        // }
      ],
      request_user: {}
    };
  },
  computed: {
    ...mapGetters(["get_insumo", "get_estoque", "get_funcionarios"])
  },
  methods: {
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_insumos",
      "criar_estoque",
      "listar_insumocategorias",
      "criar_insumo",
      "listar_estoques",
      "atualizar_insumo",
      "setPersonalizedLoading",
      "createConfirmAction"
    ]),
    open(type, prod) {
      const hasProd = prod && prod._id;
      if (!hasProd) {
        this.$store.commit("set_insumo", {});
        this.$store.commit("set_estoque", {
          type: type === "stock_up" ? "in" : "out"
        });
      } else {
        this.$store.commit("set_estoque", {
          type: type === "stock_up" ? "in" : "out",
          produto: prod || {}
        });
        this.changeProduct(prod);
      }
      this.formEstoqueDialog = true;
    },
    close() {
      this.formEstoqueDialog = false;
    },
    changeProduct(product) {
      this.get_estoque.unidade_de_medida = product.unidade_estoque_minimo;
    },
    adicionarEstoque() {
      if (this.$refs.formEstoque.validate()) {
        this.get_estoque.request_user = this.request_user;
        this.get_estoque.origem = "pdv";
        this.criar_estoque();
        this.close();
      }
    }
  }
};
</script>
