<template>
  <v-dialog
    transition="slide-x-transition"
    style="padding: 20px;"
    width="800"
    persistent
    height="800"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-model="opened"
  >
    <v-card color="#f2f2f2">
      <v-flex xs12>
        <div class="expande-horizontal wrap">
          <v-flex class="pt-3" xs12>
            <div class="expande-horizontal">
              <v-flex xs4>
                <div
                  class="fonte column expande-horizontal pa-6 pt-0 pr-0 font-color"
                >
                  <h3 :style="`color: ${$theme.primary};`">
                    {{ $helper.formataSaldo(product.seller_price || 0) }}
                  </h3>
                  <span class="grey--text fonte-micro">Total da peça</span>
                </div>
              </v-flex>
              <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs2>
                <div
                  class="fonte column expande-horizontal pa-6 pt-0 pl-0 px-3 font-color"
                >
                  <v-img
                    class="cool-shaddow"
                    style="border: 4px solid #f2f2f2; height: 60px; width: 100%; border-radius: 6px;"
                    :src="product.imagens[0]"
                  ></v-img>
                </div>
              </v-flex>
              <v-flex class="pl-0 pt-0 pr-6" xs8>
                <v-autocomplete
                  v-if="changePlate"
                  ref="plateInput"
                  :items="products.filter(p => p.type === 'Placa')"
                  item-text="nome"
                  return-object
                  placeholder="Escolha o novo tipo aqui"
                  @change="alterProduct($event)"
                  outlined
                  hide-details
                  class="mt-1"
                  append-outer-icon="mdi-swap-horizontal"
                  @click:append-outer="changePlate = false"
                ></v-autocomplete>
                <v-list class="pt-0 mt-0" v-else dark color="transparent">
                  <v-list-item
                    class="pa-0 ma-0 pl-3 fonte item-list"
                    :style="`background: ${$theme.primary};`"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="fonte-bold white--text">{{
                        product.nome
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro white--text">
                        {{
                          $helper.formataSaldo(product.ultimo_preco_de_compra)
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="nowrap">
                        <v-btn
                          @click.prevent="initChangeTable"
                          icon
                          class="mr-4"
                        >
                          <v-icon color="#f2f2f2">mdi-swap-horizontal</v-icon>
                        </v-btn>
                        <v-btn @click.prevent="close" icon class="mr-4">
                          <v-icon color="#f2f2f2">mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-flex>
            </div>
            <v-flex
              xs12
              class="pl-6 pr-6 pt-3 pb-3 adicionals fonte"
              :style="
                `min-height: ${
                  $vuetify.breakpoint.lgAndUp ? '60vh' : '73vh'
                }; max-height: 60vh; overflow: auto;`
              "
            >
              <div
                v-if="product.ficha_tecnica ? true : false"
                class="expande-horizontal column"
              >
                <v-flex xs12>
                  <div class="expande-horizontal">
                    <v-flex xs12>
                      <v-timeline class="ml-0 pl-0" dense align-top>
                        <v-timeline-item
                          icon-color="#f2f2f2"
                          icon="mdi-tag-heart-outline"
                          :color="$theme.primary"
                        >
                          <v-text-field
                            label="Descrição da peça"
                            autofocus
                            :color="$theme.primary"
                            v-model="product.descricao_personalizada"
                          ></v-text-field>
                        </v-timeline-item>
                        <v-timeline-item
                          icon-color="#f2f2f2"
                          icon="mdi-ruler-square"
                          :color="$theme.primary"
                        >
                          <h3 class="">Insira as partes abaixo</h3>
                          <v-list
                            v-if="product.ficha_tecnica"
                            class="fonte px-0 mx-0"
                            dense
                            nav
                            two-line
                            color="transparent"
                          >
                            <v-list-item
                              v-for="(item, i) in product.ficha_tecnica"
                              :key="`ficha-tecnica-${i}`"
                              style="background: #fff;"
                              @click="() => {}"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span
                                    style="font-size: 13pt;"
                                    class="font-weight-bold"
                                    >{{ item.quantity }}
                                  </span>
                                  <span class="fonte" style="font-size: 12pt;">
                                    {{ item.descricao }}
                                  </span>
                                  <span
                                    v-if="
                                      item.type !== 'Acabamento' &&
                                        $vuetify.breakpoint.smAndUp
                                    "
                                  >
                                    <v-chip
                                      :color="$theme.primary"
                                      small
                                      style="border-radius: 3px;"
                                      class="white--text"
                                    >
                                      <span class="fonte-weight-bold ">
                                        {{
                                          parseFloat(item.comprimento).toFixed(
                                            2
                                          )
                                        }}
                                        x
                                        {{
                                          parseFloat(item.largura).toFixed(2)
                                        }}
                                        {{ verifyUnit(item) }}
                                      </span>
                                    </v-chip>
                                  </span>
                                  <v-chip
                                    v-if="$vuetify.breakpoint.smAndUp"
                                    color="green"
                                    small
                                    style="border-radius: 3px;"
                                    class="mx-1 white--text"
                                    >{{
                                      $helper.formataSaldo(item.total || 0)
                                    }}</v-chip
                                  >
                                  <v-chip
                                    :color="$theme.secondary"
                                    small
                                    style="border-radius: 3px;"
                                    class="white--text"
                                    >{{ item.type }}</v-chip
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  v-if="$vuetify.breakpoint.smAndDown"
                                >
                                  <span
                                    v-if="
                                      item.type !== 'Acabamento' &&
                                        $vuetify.breakpoint.smAndDown
                                    "
                                  >
                                    <v-chip
                                      :color="$theme.primary"
                                      small
                                      class="black--text"
                                    >
                                      {{
                                        parseFloat(item.comprimento).toFixed(2)
                                      }}
                                      x
                                      {{ parseFloat(item.largura).toFixed(2) }}
                                      {{ verifyUnit(item) }}</v-chip
                                    >
                                    <v-chip
                                      color="green"
                                      small
                                      rounded
                                      class="mx-1 white--text"
                                      >{{
                                        $helper.formataSaldo(item.total || 0)
                                      }}</v-chip
                                    >
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <div class="expande-horizontal column">
                                  <v-btn
                                    @click="removeItem(item, i)"
                                    x-small
                                    text
                                    color="grey"
                                    v-if="$vuetify.breakpoint.smAndUp"
                                    >remover</v-btn
                                  >
                                  <v-btn
                                    @click="removeItem(item, i)"
                                    x-small
                                    text
                                    color="grey"
                                    v-else
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </div>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                          <span class="grey--text">Adicione abaixo</span>
                        </v-timeline-item>
                      </v-timeline>
                    </v-flex>
                  </div>
                </v-flex>
                <div class="expande-horizontal">
                  <v-flex v-if="false" xs12>
                    <v-simple-table
                      dark
                      style="witdh: 100%; height: 370px; overflow: auto; background: #f0f0f0;"
                      dense
                    >
                      <template v-slot:default>
                        <thead style="background: #EBEBEB; height: 40px;">
                          <tr
                            style="background: #EBEBEB; border: 1px solid #f0f0f0;"
                          >
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Qtd
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Desc.
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Comprimento
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Largura
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Medida
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            >
                              Preço
                            </td>
                            <td
                              class="font-weight-bold fonte"
                              style="color: #878686; border-right: 1px solid #D2D4CF;"
                            ></td>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(item, i) in product.ficha_tecnica">
                            <tr
                              class="mt-3"
                              @click="openModalAddProduct(item, `${i}`)"
                              style="background: #f0f0f0; cursor: pointer; border: 1px solid #f0f0f0;"
                              :key="`product-inn-list-${i}`"
                            >
                              <td
                                @click.prevent="abre_modal_view_caixa(item)"
                                style="color: #878686; width: 10px; border-top: 1px dashed #D2D4CF;"
                                class="fonte fonte-bold"
                              >
                                {{ item.quantity }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #494949; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ item.descricao ? item.descricao : "" }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ item.comprimento ? item.comprimento : "" }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ item.largura ? item.largura : "" }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ verifyUnit(item) }}
                              </td>
                              <td
                                class="fonte green--text fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ $helper.formataSaldo(item.total) }}
                              </td>
                              <td
                                class="fonte green--text fonte-bold"
                                style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                <v-btn @click="removeItem(item, i)" icon small>
                                  <v-icon color="red lighten-2">
                                    mdi-delete-circle-outline
                                  </v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </div>
              </div>
              <v-form ref="formAddItem">
                <div class="expande-horizontal wrap centraliza pt-2">
                  <v-flex xs4 md1>
                    <v-text-field
                      label="Qtd"
                      ref="qtdFicha"
                      outlined
                      hide-details
                      dense
                      :rules="[v => !!v || 'Preencha este campo']"
                      @keyup.enter.prevent="() => $refs.typeField.focus()"
                      v-mask="['#', '##', '###', '####']"
                      v-model="quantidade"
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    :class="{
                      'pr-0': $vuetify.breakpoint.smAndDown
                    }"
                    class="px-3"
                    xs8
                    md5
                  >
                    <v-autocomplete
                      label="Tipo"
                      ref="typeField"
                      :items="adicionais"
                      item-text="nome"
                      outlined
                      hide-details
                      dense
                      return-object
                      v-model="descricao"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex
                    v-if="descricao && descricao.type !== 'Acabamento'"
                    xs5
                    md2
                  >
                    <v-text-field
                      ref="compField"
                      label="Comprimento"
                      v-mask="['#,###', '##,###']"
                      outlined
                      hide-details
                      dense
                      v-model="comprimento"
                    ></v-text-field>
                  </v-flex>
                  <span
                    v-if="descricao && descricao.type !== 'Acabamento'"
                    class="pa-4 fonte"
                    >x</span
                  >
                  <v-flex
                    v-if="descricao && descricao.type !== 'Acabamento'"
                    xs5
                    md2
                  >
                    <v-text-field
                      label="Largura"
                      v-mask="['#,###', '##,###']"
                      outlined
                      hide-details
                      dense
                      v-model="largura"
                      @keyup.enter="addFichaTecnica"
                    ></v-text-field>
                  </v-flex>
                  <v-flex v-if="descricao" class="pt-1 pl-1" xs1>
                    <v-avatar size="32" :color="$theme.primary">
                      <v-btn @click="addFichaTecnica" icon>
                        <v-icon color="#333">mdi-plus</v-icon>
                      </v-btn>
                    </v-avatar>
                  </v-flex>
                </div>
              </v-form>
              <!-- <div class="expande-horizontal">
                <v-flex xs2>
                  <v-text-field
                    label="Quantidade"
                    type="number"
                    min="0"
                    v-mask="['#', '##', '###', '####']"
                    v-model="quantidadeAd"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-3 cl" xs6>
                  <v-autocomplete
                    label="Adicional"
                    :items="adicionais"
                    item-text="nome"
                    return-object
                    v-model="adicional"
                  ></v-autocomplete>
                </v-flex>
                <v-flex class="pt-2" xs3>
                  <v-btn @click="addFichaTecnica('adicional')" icon>
                    <v-icon :color="$theme.third"
                      >mdi-plus-circle-outline</v-icon
                    >
                  </v-btn>
                </v-flex>
              </div> -->
              <div class="expande-horizontal wrap">
                <v-flex xs12>
                  <v-list
                    class="pa-0 ma-0"
                    v-for="(adicional, index) in product.adicionais"
                    :id="`aditional_${index}`"
                    :key="adicional.nome_grupo"
                  >
                    <v-list-item class="pa-0 ma-0 pl-3 fonte item-list">
                      <v-list-item-content>
                        <v-list-item-title class="pb-1 fonte-bold">
                          {{ adicional.nome_grupo }}
                          <v-btn
                            outlined
                            class="ml-3"
                            :color="
                              adicional.obrigatorio ? 'red darken-2' : 'grey'
                            "
                            x-small
                            dark
                            >{{
                              adicional.obrigatorio ? "obrigatório" : "opcional"
                            }}</v-btn
                          >
                        </v-list-item-title>
                        <v-list-item-subtitle class="fonte-micro">
                          {{ adicional.quantidade_adicionada || 0 }} de
                          {{ adicional.maximo }} itens adicionados
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <template v-for="(item_adicional, i) in adicional.itens">
                      <v-list-item class="fonte" :key="item_adicional.nome">
                        <v-list-item-content>
                          <v-list-item-title class="fonte">
                            {{
                              item_adicional.descricao_personalizada
                                ? item_adicional.descricao_personalizada
                                : item_adicional.nome
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            class="fonte fonte-bold green--text"
                          >
                            + {{ $helper.formataSaldo(item_adicional.preco) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="expande-horizontal">
                            <v-btn
                              @click.prevent="removeAditional(i, index)"
                              v-if="
                                item_adicional &&
                                  item_adicional.quantidade_adicionada > 1
                              "
                              icon
                              x-small
                            >
                              <v-icon>mdi-minus-circle-outline</v-icon>
                            </v-btn>
                            <v-btn
                              @click.prevent="removeAditional(i, index)"
                              v-if="
                                item_adicional &&
                                  item_adicional.quantidade_adicionada === 1
                              "
                              icon
                              x-small
                            >
                              <v-icon>mdi-delete-circle-outline</v-icon>
                            </v-btn>
                            <h5 class="mx-2 fonte fonte-bold">
                              {{ item_adicional.quantidade_adicionada || 0 }}
                            </h5>
                            <v-btn @click="addAditional(i, index)" icon x-small>
                              <v-icon color="green"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
                <!-- <v-flex class="pt-6" xs12>
                  <v-textarea
                    height="100px"
                    @keyup.enter.prevent="addProduct"
                    hint="Pressione enter para adicionar rapidamente"
                    v-model="product.obs"
                    label="Observações"
                  ></v-textarea>
                </v-flex> -->
              </div>
            </v-flex>
            <div
              class="expande-horizontal wrap column centraliza px-2"
              style="border-top: 1px solid #666;"
            >
              <!-- <v-btn
                @click.prevent="removeProductQtd"
                v-if="product.quantity > 1"
                icon
                small
              >
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn>
              <v-btn
                @click.prevent="removeProductOfCart(product)"
                v-if="product.quantity === 1"
                icon
                small
              >
                <v-icon>mdi-delete-circle-outline</v-icon>
              </v-btn>
              <h3 class="mx-2 fonte fonte-bold">
                {{ product.quantity }}
              </h3> -->
              <!-- <v-btn @click="addProductQtd" icon small>
                <v-icon color="green">mdi-plus-circle-outline</v-icon>
              </v-btn> -->

              <div class="expande-horizontal centraliza">
                <v-btn
                  @click.prevent="addProduct(true)"
                  :color="$theme.primary"
                  :disabled="product.ficha_tecnica.length === 0 ? true : false"
                  class="fonte mx-1 elevation-0 white--text my-2"
                  dark
                  small
                  rounded
                >
                  Adicionar sequencia
                </v-btn>
                <v-btn
                  @click.prevent="addProduct(false)"
                  :color="$theme.primary"
                  :disabled="product.ficha_tecnica.length === 0 ? true : false"
                  class="fonte elevation-0 white--text my-2"
                  dark
                  small
                  rounded
                >
                  Adicionar uma
                </v-btn>
              </div>
              <v-btn
                @click.prevent="removeProductOfCart(product)"
                v-if="product.quantity >= 1"
                color="red lighten"
                :disabled="product.ficha_tecnica.length === 0 ? true : false"
                class="fonte elevation-0 white--text my-2"
                dark
                outlined
                x-small
                rounded
              >
                Cancelar/Remover
              </v-btn>
            </div>
          </v-flex>
        </div>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    addProductToCart: {
      type: Function,
      required: true
    },
    removeProductOfCart: {
      type: Function,
      required: true
    },
    products: {
      type: Array,
      default: () => [],
      required: true
    },
    taxFinishing: {
      type: Number,
      required: true,
      default: 30
    }
  },
  data() {
    return {
      changePlate: false,
      opened: false,
      product: {
        quantity: 1,
        imagens: [],
        ficha_tecnica: []
      },
      adicional: "",
      quantidade: "",
      quantidadeAd: "",
      comprimento: "",
      largura: "",
      descricao: "",
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4"
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"]
    };
  },
  methods: {
    verifyUnit(item) {
      if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) <= 0.1
      ) {
        return "ML";
      } else if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) > 0.1
      ) {
        return "M²";
      } else if (item.type === "Acabamento") {
        return "UND";
      }
    },
    removeItem(item, i) {
      this.product.ficha_tecnica = this.product.ficha_tecnica.filter(
        (it, index) => index !== i
      );
      this.processFinalPrice();
    },
    addFichaTecnica() {
      if (this.$refs.formAddItem.validate()) {
        const type = this.descricao.type;
        if (
          !this.product.descricao_personalizada &&
          type === "Peça Principal"
        ) {
          this.product.descricao_personalizada = this.descricao.nome;
        }

        if (type && type === "Acabamento") {
          const quantidade = parseInt(this.quantidade);
          const lucro_percentual = parseFloat(
            `${this.taxFinishing}`.replace(",", ".")
          );
          const preco = parseFloat(
            this.descricao.ultimo_preco_de_compra.replace(",", ".")
          );
          const calculo = preco;
          const taxa = (calculo / 100) * lucro_percentual;
          const total = parseFloat(quantidade * (taxa + calculo)).toFixed(2);
          this.product.ficha_tecnica.push({
            comprimento: 0,
            largura: 0,
            preco,
            descricao: this.descricao.nome,
            calculo,
            taxa,
            type: type,
            total,
            quantity: quantidade
          });
          this.$refs.qtdFicha.focus();
        } else if (
          type &&
          (type === "Peça Principal" || type === "Peça Secundária")
        ) {
          const comprimento = parseFloat(
            this.comprimento.replace(",", ".") || 0
          );
          const quantidade = parseInt(this.quantidade);
          const largura = parseFloat(this.largura.replace(",", ".") || 0);
          const lucro_percentual = parseFloat(
            `${this.taxFinishing}`.replace(",", ".")
          );
          const preco = parseFloat(
            this.product.ultimo_preco_de_compra.replace(",", ".")
          );
          let calculo, taxa;
          if (largura <= 0.1) {
            let dezporcento = (preco / 100) * 10;
            calculo = quantidade * comprimento * dezporcento;
            taxa = (calculo / 100) * lucro_percentual;
          } else {
            calculo = quantidade * comprimento * largura * preco;
            taxa = (calculo / 100) * lucro_percentual;
          }
          this.product.ficha_tecnica.push({
            comprimento: comprimento,
            largura: largura,
            preco,
            descricao: this.descricao.nome,
            calculo,
            type: type,
            taxa,
            total: parseFloat(taxa + calculo).toFixed(2),
            quantity: quantidade
          });
          this.$refs.qtdFicha.focus();
          this.quantidade = 1;
          this.comprimento = "";
          this.largura = "";
          this.descricao = "";
        }
        this.processFinalPrice();
      }
    },
    ...mapActions(["createGlobalMessage"]),
    open(product) {
      this.opened = true;
      this.product = {
        ...product,
        ficha_tecnica: product.ficha_tecnica ? product.ficha_tecnica : [],
        quantity: product.quantity ? product.quantity : 1,
        seller_price: product.quantity
          ? product.seller_price
          : product.preco_com_markup
      };
    },
    alterProduct(p) {
      let ficha_tecnica = [];
      this.product.ficha_tecnica.map(item => {
        const quantidade = parseInt(item.quantity);
        const lucro_percentual = parseFloat(
          `${this.taxFinishing}`.replace(",", ".")
        );
        const preco = parseFloat(p.ultimo_preco_de_compra.replace(",", "."));

        if (item.type === "Acabamento") {
          const calculo = parseFloat(item.preco);
          const taxa = (calculo / 100) * lucro_percentual;
          item.total = parseFloat(quantidade * (taxa + calculo)).toFixed(2);
          item.preco = parseFloat(item.preco);
          item.calculo = calculo;
          item.taxa = taxa;
        } else if (
          item.type === "Peça Principal" ||
          item.type === "Peça Secundária"
        ) {
          const comprimento = parseFloat(item.comprimento);
          const largura = parseFloat(item.largura);
          //calculo é isso
          let calculo;
          if (largura <= 0.1) {
            let dezporcento = (preco / 100) * 10;
            calculo = comprimento * dezporcento;
          } else {
            calculo = comprimento * largura * preco;
          }
          // até aqui
          const taxa = (calculo / 100) * lucro_percentual;
          item.total = parseFloat(quantidade * (taxa + calculo)).toFixed(2);
          item.preco = preco;
          item.calculo = calculo;
          item.taxa = taxa;
        }
        ficha_tecnica.push(item);
      });
      this.product = {
        ...this.product,
        ...p,
        ficha_tecnica
      };
      this.changePlate = false;
      this.processFinalPrice();
    },
    close() {
      this.opened = false;
      this.product = {
        quantity: 1,
        imagens: [],
        ficha_tecnica: []
      };
    },
    initChangeTable() {
      this.changePlate = true;
      setTimeout(() => {
        this.$refs.plateInput.focus();
      }, 300);
    },
    addAditional(index_item_adicional, index_adicional) {
      let item_adicional = this.product.adicionais[index_adicional];
      if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada < parseInt(item_adicional.maximo)
      ) {
        item_adicional.quantidade_adicionada++;
        item_adicional.itens[index_item_adicional].quantidade_adicionada
          ? item_adicional.itens[index_item_adicional].quantidade_adicionada++
          : (item_adicional.itens[
              index_item_adicional
            ].quantidade_adicionada = 1);
      } else if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada === parseInt(item_adicional.maximo)
      ) {
        this.createGlobalMessage({
          type: "info",
          icon: "mdi-info",
          message: "Opa, quantidade máxima atingida!",
          timeout: 6000
        });
      } else if (!item_adicional.quantidade_adicionada) {
        item_adicional.quantidade_adicionada = 1;
        item_adicional.itens[index_item_adicional].quantidade_adicionada = 1;
      }

      this.product.adicionais[index_adicional] = item_adicional;
      this.$forceUpdate();
      this.processFinalPrice();
    },
    removeAditional(index_item_adicional, index_adicional) {
      let item_adicional = this.product.adicionais[index_adicional];
      if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada > 1
      ) {
        item_adicional.quantidade_adicionada--;
        item_adicional.itens[index_item_adicional].quantidade_adicionada--;
      } else if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada === 1
      ) {
        item_adicional.quantidade_adicionada = 0;
        item_adicional.itens[index_item_adicional].quantidade_adicionada = 0;
      }
      this.product.adicionais[index_adicional] = item_adicional;
      this.processFinalPrice();
    },
    // thiss process product.seller_price
    processFinalPrice() {
      // const aditionals = this.product.adicionais || [];
      const ficha_tecnica = this.product.ficha_tecnica || [];
      let initial_price = 0;
      let aditional_total_price = 0;
      let ficha_total_price = 0;
      // let acabamento_total_price = 0;
      // aditionals.map(adicional => {
      //   adicional.itens.map(item => {
      //     if (item.quantidade_adicionada) {
      //       aditional_total_price +=
      //         parseInt(item.quantidade_adicionada) * parseFloat(item.preco);
      //     }
      //   });
      // });
      ficha_tecnica.map(ficha => {
        if (ficha.quantity) {
          ficha_total_price += parseFloat(ficha.total);
        }
      });
      this.product.seller_price =
        initial_price + aditional_total_price + ficha_total_price;
      // this.product.seller_price =
      //   this.product.quantity *
      //   (initial_price + aditional_total_price + ficha_total_price);
      // this.product.aditional_total_price = aditional_total_price;
      this.product.ficha_total_price = ficha_total_price;
      this.$forceUpdate();
    },
    addProduct(sequencial) {
      const product = this.product;
      this.addProductToCart(product);
      if (sequencial) {
        let sequential_product = {
          ...product,
          descricao_personalizada: "",
          ficha_tecnica: [],
          indexProd: undefined
        };
        this.product = sequential_product;
        setTimeout(() => {
          this.open(sequential_product);
        }, 300);
      }
    },
    addProductQtd() {
      this.product.quantity++;
      this.processFinalPrice();
    },
    removeProductQtd() {
      if (this.product.quantity > 1) {
        this.product.quantity--;
      }
      this.processFinalPrice();
    }
  },
  computed: {
    adicionais() {
      const payload = this.products.filter(
        i =>
          i.type === "Acabamento" ||
          i.type === "Peça Principal" ||
          i.type === "Peça Secundária"
      );

      return payload;
    }
  }
};
</script>

<style scoped>
.item-list {
  background: #0b0b0b;
  border-radius: 6px;
}
.adicionals::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.adicionals::-webkit-scrollbar-track {
  background: rgb(8, 8, 8); /* color of the tracking area */
  border-radius: 6px;
}

.adicionals::-webkit-scrollbar-thumb {
  background-color: #484c4e; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.font-orange {
  color: #e1673c;
}
</style>
