<template>
  <v-dialog
    width="800"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_fechamento_caixa"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal column windows-style">
        <v-list nav color="transparent" class="pa-0 ma-0 pt-0 pb-2">
          <v-list-item class="fonte">
            <v-avatar color="#333" dark class="mr-2">
              <v-icon :color="$theme.primary">mdi-cash-register</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="">
                Fechamento de caixa
              </v-list-item-title>
              <span class="fonte ml-0 fonteMini grey--text">
                Informe a situação do caixa com o montante recebido em cada
                forma de pagamento.
              </span>
            </v-list-item-content>
            <v-list-item-action v-if="!dueBox()">
              <v-avatar
                @click="fecha_modal_fechamento_caixa"
                style="cursor: pointer;"
                :color="$theme.primary"
                dark
                class="mr-2"
              >
                <v-icon color="black">
                  mdi-close
                </v-icon>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>

      <div class="expande-horizontal windows-style-content wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-3" xs12>
              <v-text-field
                v-model="get_caixa.dinheiro_cego"
                label="Total em dinheiro"
                placeholder="ex: 17,40"
                prefix="R$"
                v-mask="[
                  '#,#',
                  '#,##',
                  '##,##',
                  '###,##',
                  '####,##',
                  '#####,##'
                ]"
                outlined
                @keyup.enter.prevent="fechar_caixa"
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-3" xs12>
              <v-text-field
                v-model="get_caixa.debito_cego"
                label="Total em débito"
                outlined
                placeholder="ex: 175,00"
                prefix="R$"
                v-mask="[
                  '#,#',
                  '#,##',
                  '##,##',
                  '###,##',
                  '####,##',
                  '#####,##'
                ]"
                @keyup.enter.prevent="fechar_caixa"
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-3" xs12>
              <v-text-field
                v-model="get_caixa.credito_cego"
                label="Total no crédito"
                outlined
                prefix="R$"
                placeholder="ex: 1375,00"
                v-mask="[
                  '#,#',
                  '#,##',
                  '##,##',
                  '###,##',
                  '####,##',
                  '#####,##'
                ]"
                @keyup.enter.prevent="fechar_caixa"
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-3" xs12>
              <v-text-field
                v-model="get_caixa.pix_cego"
                label="Total no píx"
                placeholder="ex: 13575,00"
                outlined
                prefix="R$"
                v-mask="[
                  '#,#',
                  '#,##',
                  '##,##',
                  '###,##',
                  '####,##',
                  '#####,##'
                ]"
                @keyup.enter.prevent="fechar_caixa"
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
          </v-form>
        </v-flex>
        <v-btn
          rounded
          class="elevation-0 ml-3 mb-3"
          :color="$theme.primary"
          @click="fechar_caixa"
        >
          <span class="fonte"> Fechar Caixa </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_modal_fechamento_caixa", "get_caixa", "get_caixas"])
  },
  methods: {
    ...mapActions([
      "listarMeuCaixa",
      "fechar_caixa",
      "fecha_modal_fechamento_caixa"
    ]),
    dueBox() {
      if (this.get_caixa.status_atual === "aberto") {
        const dueDate = this.$moment().isAfter(
          this.$moment(this.get_caixa.created_at)
            .add(1, "days")
            .format()
        );
        if (this.get_caixa.status_atual == "aberto" && dueDate) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }
};
</script>
