<template>
  <v-dialog transition="slide-x-transition" v-model="opened" width="350">
    <v-card style="border-radius: 20px;" class="expande-horizontal wrap">
      <div class="expande-horizontal windows-style">
        <v-list nav color="transparent" class="pa-0 ma-0 pt-0 pb-2">
          <v-list-item class="fonte pt-2">
            <v-avatar color="#333" dark class="mr-2">
              <v-icon :color="$theme.primary">mdi-cash-register</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="">
                Lançamento de caixa
              </v-list-item-title>
              <span class="fonte ml-0 fonteMini grey--text">
                Informe o tipo de lançamento abaixo.
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar
                size="31"
                @click="close"
                style="cursor: pointer;"
                :color="$theme.primary"
                dark
                class="mr-2"
              >
                <v-icon color="black">
                  mdi-close
                </v-icon>
              </v-avatar>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
      <v-flex xs12>
        <div
          style="min-height: 20vh;"
          class="expande-horizontal fonte windows-style-content wrap"
        >
          <v-flex xs12 class="pa-3">
            <v-list class="mx-0 px-0 pl-1" nav color="transparent" dense>
              <v-list-item @click="$emit('to_receive')" class="mb-3">
                <v-avatar size="31" class="mr-3" color="#333">
                  <v-icon :color="$theme.primary">
                    mdi-call-received
                  </v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="">
                    Entrada de valores
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="$emit('to_pay')" class="mb-3">
                <v-avatar size="31" class="mr-3" color="#333">
                  <v-icon :color="$theme.primary">
                    mdi-call-made
                  </v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="">
                    Saída de valores
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="$emit('stock_down')" class="mb-3">
                <v-avatar size="31" class="mr-3" color="#333">
                  <v-icon :color="$theme.primary">
                    mdi-tray-arrow-up
                  </v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="">
                    Saída de material
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="$emit('stock_up')" class="mb-3">
                <v-avatar size="31" class="mr-3" color="#333">
                  <v-icon :color="$theme.primary">
                    mdi-tray-arrow-down
                  </v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="">
                    Entrada de material
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-flex>
        </div>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      opened: false,
      transaction: {}
    };
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    }
  }
};
</script>
