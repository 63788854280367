<template>
  <div>
    <basePlugin
      v-for="item in list"
      :item="item"
      :key="item._id"
      :classFonte="`fonteMini`"
    />
    <v-menu :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-tag-plus-outline</v-icon>
        </v-btn>
      </template>
      <v-card class="pb-0" width="380">
        <v-list nav dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weigth-bold fonte">
                Tags
              </v-list-item-title>
              <span class="fonte fonteMini mt-1 grey--text">
                Adicione tags para organizar melhor seus pedidos
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense nav>
          <v-list-item
            style="height: 20px;"
            v-for="(item, index) in items"
            :key="index"
          >
            <v-checkbox
              x-small
              @click="
                !item.custom_value.active
                  ? removeTagFromSale(item)
                  : addTagOnSale(item)
              "
              v-model="item.custom_value.active"
            ></v-checkbox>
            <v-list-item-content
              class="white--text fonte"
              :style="
                `height: 40px; border-radius: 6px; padding-left: 6px; background: ${item.custom_value.color};`
              "
            >
              <v-list-item-title>{{
                item.custom_value.name
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="editTag(item)" small icon>
                <v-icon size="21">mdi-pencil-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div v-if="showEditTagForm" style="background-color: #f2f2f2;" class="px-3 expande-horizontal column pt-3 mt-3">
          <div class="expande-horizontal centraliza">
            <v-icon @click="closeEditTag" style="cursor: pointer; color: green;" class="mr-1 mb-1" size="15">mdi-arrow-left</v-icon>
            <span style="color: #444;" class="fonteMini mb-2">Você está {{ formTag._id ? 'editando' : 'criando' }} uma tag:</span>
          </div>
          <div class="expande-horizontal">
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <div
                    class="pt-2"
                    style="display: flex; flex-direction: column;"
                  >
                    <v-avatar
                      style="border: 3px solid #fff;"
                      :size="23"
                      :color="formTag.custom_value.color"
                    ></v-avatar>
                    <!-- <span class="fonte fonteMini grey--text">cor</span> -->
                  </div>
                </v-btn>
              </template>
              <v-color-picker
                dot-size="13"
                hide-inputs
                hide-mode-switch
                mode="hexa"
                v-model="formTag.custom_value.color"
                show-swatches
                swatches-max-height="173"
              ></v-color-picker>
            </v-menu>
            <v-text-field
              v-model="formTag.custom_value.name"
              placeholder="Nome da tag"
              dense
              focus
              outlined
              class="ml-0 fonte"
              @keyup.enter.prevent="createOrUpdateTag"
              hide-details
            />
            <v-btn class="mr-2" @click="createOrUpdateTag" icon>
              <v-icon :color="$theme.secondary">mdi-send</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="pt-2 px-3 expande-horizontal centraliza column" style="background-color: #f2f2f2;">
          <span class="fonteMini grey--text">
            Opções
          </span>
          <div class="expande-horizontal centraliza py-2">
            <v-btn class="mr-1" x-small rounded color="blue" dark v-if="!showEditTagForm" @click="showEditTag">Nova tag</v-btn>

            <v-btn class="mr-1" x-small rounded color="green" dark v-if="showEditTagForm && !formTag._id" @click="showEditTag">Criar tag</v-btn>
            <v-btn class="mr-1" x-small rounded color="green" dark v-if="formTag._id" @click="createOrUpdateTag">Salvar tag</v-btn>

            <v-btn class="mr-1" x-small rounded color="orange" text  v-if="formTag._id" @click="openModalDeleteTag">Apagar tag selecionada</v-btn>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import basePlugin from "../base.vue";
export default {
  props: {
    dataOrigin: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showEditTagForm: false,
      formTag: {
        custom_key: "tag_sale",
        custom_value: {
          name: "",
          color: this.$theme.secondary
        }
      },
      items: []
    };
  },
  methods: {
    ...mapActions(["atualizar_gestordepedido", "createConfirmAction", "createGlobalMessage", "listar_customs"]),
    listTags() {
      this.$run("custom-key/read", ["tag_sale"]).then(res => {
        this.$store.dispatch("add_custom_key_in_indexeddb", res.data);
        if (
          this.get_gestordepedido.compra &&
          Array.isArray(this.get_gestordepedido.compra.tags)
        ) {
          this.items = res.data.map(item => {
            if (
              this.get_gestordepedido.compra.tags &&
              this.get_gestordepedido.compra.tags.find(t => t._id === item._id)
            ) {
              item.custom_value.active = true;
              return item;
            } else {
              item.custom_value.active = false;
              return item;
            }
          });
        } else {
          this.items = res.data;
        }
      });
    },
    openModalDeleteTag() {
      this.createConfirmAction({
        message: "Deseja remover esta tag?",
        action: this.removeTag,
        action_value: this.formTag,
        isScoped: true
      })
    },
    removeTag(tag) {
      this.$run('custom-key/delete', tag)
        .then(() => {
          this.createGlobalMessage({
            timeout: 4000,
            message: "Tag removida",
            type: 'success'
          })
          this.listTags();
          this.closeEditTag();
        })
    },
    showEditTag() {
      this.showEditTagForm = true;
    },
    editTag(tag) {
      this.showEditTagForm = true;
      this.formTag = tag;
    },
    closeEditTag() {
      this.formTag = {
        custom_key: "tag_sale",
        custom_value: {
          name: "",
          color: this.$theme.secondary
        }
      };
      this.showEditTagForm = false;
    },
    addTagOnSale(tag) {
      if (this.get_gestordepedido.compra.tags) {
        this.get_gestordepedido.compra.tags.push(tag);
      } else {
        this.get_gestordepedido.compra.tags = [tag];
      }
      this.atualizar_gestordepedido(true);
    },
    removeTagFromSale(tag) {
      this.get_gestordepedido.compra.tags = this.get_gestordepedido.compra.tags.filter(
        t => t._id !== tag._id
      );
      this.atualizar_gestordepedido(true);
    },
    createOrUpdateTag() {
      if (this.formTag._id) {
        this.$run("custom-key/update", this.formTag).then(res => {
          this.$store.dispatch("add_custom_key_in_indexeddb", [res.data]);
          this.items = this.items.map(item => {
            if (item._id === res.data._id) {
              return res.data;
            } else {
              return item;
            }
          });
          this.formTag = {
            custom_key: "tag_sale",
            custom_value: {
              name: "",
              color: this.$theme.secondary
            }
          };
          this.closeEditTag();
        });
      } else {
        this.$run("custom-key/create", this.formTag).then(res => {
          this.$store.dispatch("add_custom_key_in_indexeddb", [res.data]);
          this.formTag = {
            custom_key: "tag_sale",
            custom_value: {
              name: "",
              color: this.$theme.secondary
            }
          };
          this.items.push(res.data);
          this.closeEditTag();
        });
      }
    }
  },
  computed: {
    list() {
      return (
        this.dataOrigin ||
        [
          // {
          //   _id: "teste",
          //   custom_key: "tag_sale",
          //   custom_value: {
          //     name: "",
          //     color: "#8E24AA"
          //   }
          // }
        ]
      );
    },
    ...mapGetters(["get_gestordepedido"])
  },
  watch: {
    get_gestordepedido: {
      handler() {
        if (
          this.get_gestordepedido.compra &&
          Array.isArray(this.get_gestordepedido.compra.tags)
        ) {
          this.items = this.items.map(item => {
            if (
              this.get_gestordepedido.compra.tags &&
              this.get_gestordepedido.compra.tags.find(t => t._id === item._id)
            ) {
              item.custom_value.active = true;
              return item;
            } else {
              item.custom_value.active = false;
              return item;
            }
          });
        } else {
          this.items = this.items.map(item => {
            item.custom_value.active = false;
            return item;
          });
        }
      },
      deep: true
    }
  },
  components: {
    basePlugin
  },
  created() {
    this.listTags()
  }
};
</script>

<style scoped>
.sale {
  width: 100%;
  height: 100%;
  background: #333;
}
</style>
