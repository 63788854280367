<template>
  <v-menu light open-on-hover bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        dark
        style="cursor: pointer; border-radius: 6px;"
        :color="item.custom_value.color"
        v-bind="attrs"
        class="mr-1 fonte"
        v-on="on"
      >
        {{ item.custom_value.name }}
      </v-chip>
    </template>

    <!-- <v-card light>
      <v-list color="transparent" dense>
        <v-list-item class="fonte">
          <v-avatar class="mr-2">
            <v-img v-if="photo" :src="photo"></v-img>
            <v-icon v-else>mdi-account-circle</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{
              member.name || member.nome
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ member.email }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="fonteMini font-weight-bold">
              Como {{ member.paper }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card> -->
  </v-menu>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>
