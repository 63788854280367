<template>
  <div v-if="dataOrigin.state === 'icon'" class="icon">no need</div>
</template>

<script>
export default {
  props: {
    dataOrigin: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.icon {
  width: 100%;
  height: 100%;
  background: #333;
}
</style>
