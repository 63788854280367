<template>
  <div class="nowrap-container">
    <progress-view :dataOrigin="dataOrigin" :dataSale="dataSale" />
  </div>
</template>

<script>
import progressView from "./progressView/index.vue";

export default {
  name: "Tags",
  props: {
    dataOrigin: {
      type: Array,
      required: true
    },
    dataSale: {
      type: Object,
      required: true
    }
  },
  components: {
    progressView
  }
};
</script>

<style lang="scss" scoped>
.nowrap-container {
  display: flex;
  flex-wrap: nowrap !important;
}
</style>
